<template>
  <div class="page">
    <nav class="navigation">
        <div class="lien" @click="retour">
            Retour / Back
        </div>
    </nav>
    <div class="titre">
        Exemples de sites vitrine
    </div>
    
    <div class="exemples site1">
        <div class="site">
            <img src="textures/photo1.png" alt="">
        </div>
        <div class="detail" id="det1">
            <h3>Site Fictif pour un photographe</h3>
            <p>Ecrit en Nuxtjs</p>
            <p>animation en GSAP</p>
            <p>3D en Three.js et cannonJs pour la physique</p>
            <button class="btn" @click="OuvreSite(1)">
                Voir le site
            </button>
        </div>
    </div>
    <div class="exemples site2">
        
        <div class="detail" id="det2">
            <h3>Site Fictif touristique de la Vendée</h3>
            <p>Ecrit en VueJS</p>
            <p>animation en GSAP</p>
            
            <button class="btn" @click="OuvreSite(2)">
                Voir le site
            </button>
        </div>
        <div class="site">
            <img src="textures/vitrine2.jpg" alt="">
        </div>
    </div>
  </div>
</template>

<script>

import gsap from "gsap";

export default {
    name: 'vitrine',
    mounted() {
        const TL = new gsap.timeline({ paused: true})

        TL.to('.site1', {left: '5%', duration: 2, ease:'power2.out'})
        TL.to('#det1', {opacity: 1, duration: 1, ease:'power2.out'})
        TL.to('.site2', {right: '5%', duration: 2, ease:'power2.out'}, "-=1.5")
        TL.to('#det2', {opacity: 1, duration: 1, ease:'power2.out'})
        TL.play()


    },
    methods: {
        OuvreSite(n) {
            switch (n) {
                case 1:
                    window.open("http://atomdev-sitephoto.s3-website.eu-west-3.amazonaws.com", '_blank')
                    break;
                case 2:
                    window.open('http://atomdev-gite01.s3-website.eu-west-3.amazonaws.com/', '_blank')
                    break;
            }
        },
        retour() {
            this.$store.state.modeProjet = "rapide"
            this.$router.push("/project3d");
        },
    }
}
</script>

<style scoped lang="scss">

    .page {
        width: 100%;
        max-width: 1440px;
        min-height: 100vh;
        background-color: #0f0f0f;
        overflow: hidden;
        margin: 0 auto;

        .titre {
            width: 100%;
            font-size: 30px;
            text-align: center;
            color: #f0f0f0;
            padding: 25px 0 15px 0;
            transition: 0.2s ease-in;
        }

        .exemples {
            width: 90%;
            height: 450px;
            
            position: absolute;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            flex-wrap: wrap;
            color: #f0f0f0;
            .site {
                width: 40vw;
                max-width: 600px;
                

                img {
                    width: 100%;
                    height: 100%;
                }
            }
            .detail {
                 width: 40vw;
                 opacity: 0;
                 
            }

            #det2 {
                text-align: right;
            }
        }
    }

    .site1 {
        top: 100px;
        left: -110%;
    }

    .site2 {
        top: 600px;
        right: -110%;
    }

.retour {
    font-size: 20px;
    color: white;
    border: white 1px solid;
    padding: 5px;
    position: absolute;
    right: 20px;
    top: 15px;
    cursor: pointer;
    opacity: 1;
    transition: 0.5s ease-in;

    &:hover {
      background-color: white;
      color: black;
    }
  }

  .btn {
    font-size: 17px;
    color: white;
    border: white 1px solid;
    background-color: #0f0f0f;
    padding: 5px;
    cursor: pointer;
    transition: 0.5s ease-in;

    &:hover {
      background-color: white;
      color: black;
    }
  }

  @media screen and (max-width: 600px) {
    .page {
        .titre {
            font-size: 15px;
        }
        .retour {
            font-size: 10px;
        }
        .exemples {
            width: 98%;
            .detail {
                font-size: 0.8em;
            }
            .site {
                width: 55vw;
            }
        }
    }
  }
</style>