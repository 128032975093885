<template>
  <div class="container">
    <div class="techno">
        <div class="titre">
            Technologies utilisées
        </div>
        <div class="detail">
            <div class="ligne">
                <div class="icone">
                    <img src="../../public/lettres/l12.png" alt="">
                </div>
                <span>Vue js 3 (composition API + TypeScript)</span>
            </div>
            <div class="ligne">
                <div class="icone">
                    <img src="../../public/lettres/l13.png" alt="">
                </div>
                <span>VueX</span>
            </div>
            <div class="sep">

            </div>
            <div class="ligne">
                <div class="icone">
                    <img src="../../public/lettres/l14.png" alt="">
                </div>
                <span>NodeJs (TypeScript)</span>
            </div>
            <div class="ligne">
                <div class="icone">
                    <img src="../../public/lettres/l30.png" alt="">
                </div>
                <span>Express</span>
            </div>
            <div class="ligne">
                <div class="icone">
                    <img src="../../public/lettres/l20.png" alt="">
                </div>
                <span>MongoDB</span>
            </div>
            <div class="ligne">
                <div class="icone">
                    <img src="../../public/lettres/l24.png" alt="">
                </div>
                <span>Hébergement AWS</span>
            </div>
             <div class="ligne">
                <span>+ JWT / BCrypt / VueCookies ...</span>
            </div>
        </div>
    </div>
    <div class="resume">
        <div class="titre">
            Détail 
        </div>
        <div class="detail">
            <p>
                WEB Appli de Gestion de projets
            </p>
            <p>Et plus précisement:</p>
            <p>Créer facilement des projets</p>
            <p>Gérer l'avancement des tâches</p>
            <p>Chaque tâche peut avoir sa propre date de livraison</p>
            <p>Plannig des tâches avec gestion des retards</p>
            <p>Gestion des ToDos</p>
            <p>Partagez l'avancement des projets avec vos clients</p>
            <p>
                <i>C'est une application que j'ai développée pour mon entreprise, et que j'envisage de distribuer...</i>
            </p>
        </div>
    </div>
  </div>
</template>

<script>
export default {
    name: 'Progress'
}
</script>

<style lang="scss" scoped>
.container {
    width: 100%;
    margin: 50px 0 12px 0;
    display: flex;
    justify-content: space-evenly;
    align-items: flex-start;
    flex-wrap: wrap;
    color: #f0f0f0;
    .techno, .resume {
        border: 1px solid #b0b0b0;
        width: 30%;
        max-width: 450px;

        .titre {
            font-size: 1.3em;
            font-weight: 600;
            width: 100%;
            text-align: center;
            border-bottom: 1px solid #b0b0b0;
            margin: 0 0 25px 0;
        }

        .detail {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            width: 100%;
            flex-direction: column;

            .ligne {
                width: 100%;
                padding: 0.3em 0;
                display: flex;
                margin: 0 0 0 25px;

                .icone {
                    width: 32px;
                    height: 32px;
                    border-radius: 50%;
                    background-color: #fff;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    img {
                        width: 22px;
                        height: 22px;
                    }

                }

                span {
                    margin: 0 0 0 15px;
                }

               
            }

             p {
                    width: 100%;
                    text-align: center;
                }

            .sep{
                width: 100%;
                height: 2px;
                border-bottom: 1px solid #3f3f3f;
                margin: 8px 0;
            }
        }
    }
}
</style>