<template>
  <div class="entete">
    <div class="navigation">
      <div class="lien" @click="retour">
        Retour / Back
      </div>
    </div>
    <div class="titre">
      {{ titreProject }}
    </div>
  </div>
  <div class="acceuil" id="acceuil" @mousemove="onMouseMove">
    <div class="prev" @click="loadPrevious">
      &#60;
    </div>

    <div class="suivant" @click="loadNext">
      &#62;
    </div>
  </div>
  <!-- <div class="navig"></div> -->
  <GC v-if="directory == 'GC'"></GC>
  <SN v-if="directory == 'SN'"></SN>
  <Progress v-if="directory == 'PROGRESS'"></Progress>
</template>

<script>
//import projet from '../components/Projet.vue'
import * as THREE from "three";
import gsap from "gsap";

import GC from "../components/GC.vue";
import SN from "../components/SN.vue";
import Progress from '../components/Progress.vue'
import VueCookies from "vue-cookies";

export default {
  name: "Projects",
  data() {
    return {
      mouseX: 0,
      mouseY: 0,
      windowHalfX: 0,
      windowHalfY: 0,
      page: 1,
      lngDeplace: 500,
      nbSlide: 1,
      directory: "",
      titreProject: "",
    };
  },
  components: {
    GC,
    SN,
    Progress,
  },
  async mounted() {
    this.windowHalfX = window.innerWidth / 2;
    this.windowHalfY = window.innerHeight / 2;
    /* this.nbSlide = this.$store.state.nbSlide;
    this.directory = this.$store.state.directory;
    this.titreProject = this.$store.state.titreProject; */
    this.nbSlide = localStorage.projectNbSlides;
    this.directory = localStorage.projectDirectory;
    this.titreProject = localStorage.projectTitle;
    window.addEventListener("resize", this.onWindowResize);
    window.addEventListener("popstate", this.stopAnimation);
    await this.Init3D(false);
    this.animate();
    this.animeOpen();
    console.log(localStorage.projectInfos[0],localStorage.projectInfos[1],localStorage.projectInfos[2]);
    //this.renderer.render( this.scene, this.camera );
  },
  methods: {
    async Init3D(simple) {
      //chargement des textures des plane
      //photo de fond
      var texture = new THREE.TextureLoader().load(
        "textures/" + this.directory + "/photo" + this.page + ".jpg"
      );
      texture.wrapS = THREE.RepeatWrapping;
      texture.wrapT = THREE.RepeatWrapping;
      texture.repeat.set(1, 1);
      texture.needsUpdate = true;
      var material1 = new THREE.MeshBasicMaterial({
        color: 0xffffff,
        side: THREE.DoubleSide,
        map: texture,
        transparent: true,
      });
      material1.needsUpdate = true;
      //titre
      texture = new THREE.TextureLoader().load(
        "textures/" + this.directory + "/titre" + this.page + ".png"
      );
      console.log("t2");
      texture.wrapS = THREE.RepeatWrapping;
      texture.wrapT = THREE.RepeatWrapping;
      texture.repeat.set(1, 1);
      texture.needsUpdate = true;
      var material2 = new THREE.MeshBasicMaterial({
        color: 0xffffff,
        side: THREE.DoubleSide,
        map: texture,
        transparent: true,
        opacity: 0.6,
      });
      material2.needsUpdate = true;

      //detail
      texture = new THREE.TextureLoader().load(
        "textures/" + this.directory + "/detail" + this.page + ".png"
      );
      texture.wrapS = THREE.RepeatWrapping;
      texture.wrapT = THREE.RepeatWrapping;
      texture.repeat.set(1, 1);
      texture.needsUpdate = true;
      var material3 = new THREE.MeshBasicMaterial({
        color: 0xffffff,
        side: THREE.DoubleSide,
        map: texture,
        transparent: true,
        opacity: 0.6,
      });

      console.log("init3d");
      if (simple != true) {
        this.scene = new THREE.Scene();
        //this.scene.fog = new THREE.FogExp2(0x000000, 0.004);
        this.camera = new THREE.PerspectiveCamera(
          75,
          window.innerWidth / window.innerHeight,
          0.1,
          1000
        );
        this.camera.lookAt(0, 0, 0);
        this.renderer = new THREE.WebGLRenderer({ antialias: true });
        let acceuil = document.getElementById("acceuil");
        this.renderer.setSize(window.innerWidth, window.innerHeight * 0.75);
        this.renderer.setClearColor(0x0f0f0f, 1);

        acceuil.appendChild(this.renderer.domElement);
      } else {
        //return
        /*console.log('supp plane')
                this.scene.remove( this.fond )
                this.scene.remove( this.titre )
                this.scene.remove( this.detail )*/
        this.fond.material = material1;
        this.fond.needsUpdate;
        this.titre.material = material2;
        this.titre.needsUpdate;
        this.detail.material = material3;
        this.detail.needsUpdate;
        return;
      }
      var geometry = new THREE.PlaneGeometry(200, 150, 10, 10);
      this.fond = new THREE.Mesh(geometry, material1);
      this.scene.add(this.fond);
      if (simple == true) {
        this.fond.position.x = -this.lngDeplace;
      }
      geometry = new THREE.PlaneGeometry(80, 15, 10, 10);
      this.titre = new THREE.Mesh(geometry, material2);
      if (simple != true) {
        this.titre.position.x = -60;
      } else {
        this.titre.position.x = -this.lngDeplace;
      }
      this.titre.position.y = 55;
      this.titre.position.z = 25;
      this.scene.add(this.titre);
      geometry = new THREE.PlaneGeometry(80, 45, 10, 10);

      this.detail = new THREE.Mesh(geometry, material3);
      if (simple != true) {
        this.detail.position.x = 70;
      } else {
        this.detail.position.x = this.lngDeplace;
      }
      this.detail.position.y = -50;
      this.detail.position.z = 20;
      this.scene.add(this.detail);
      this.camera.position.y = 0;
      //if (simple!=true) {this.camera.position.z = 350}

      console.log("fin init");
    },
    render() {
      console.log("render");
      let x1 = this.mouseX; //- parseFloat( this.camera.position.x)
      let y1 = this.mouseY; //parseFloat( this.mouseY - this.camera.position.y)
      if (x1 == undefined) {
        x1 = 1;
      }
      if (y1 == undefined) {
        y1 = 1;
      }
      //console.log('x: ' + x1 + '  -y:' + y1)
      this.camera.position.x = x1 * 0.015; //* 0.5;
      this.camera.position.y = y1 * 0.015; //* 0.5;
      this.camera.lookAt(this.scene.position);
      this.renderer.render(this.scene, this.camera);
      console.log("fin render");
    },
    onMouseMove(event) {
      if (event.isPrimary === false) return;
      this.mouseX = event.clientX - this.windowHalfX;
      this.mouseY = event.clientY - this.windowHalfY;
      //this.render();
    },
    animate() {
      this.idAnim = requestAnimationFrame(this.animate);
      let x1 = this.mouseX; //- parseFloat( this.camera.position.x)
      let y1 = this.mouseY; //parseFloat( this.mouseY - this.camera.position.y)
      if (x1 == undefined) {
        x1 = 1;
      }
      if (y1 == undefined) {
        y1 = 1;
      }
      //console.log(this.camera.position.z)
      this.camera.position.x = x1 * 0.015; //* 0.5;
      this.camera.position.y = y1 * 0.015; //* 0.5;
      this.camera.lookAt(this.scene.position);
      this.renderer.render(this.scene, this.camera);
    },
    retour() {
      this.stopAnimation();
      this.$store.state.modeProjet = "rapide"
      this.$router.push("/project3d");
    },
    onWindowResize(e) {
      let X = window.innerWidth;
      let Y = window.innerHeight;
      /* if (X < Y) {
                let tmpX = X
                X = Y
                Y = tmpX
            } */
      this.windowHalfX = window.innerWidth / 2;
      this.windowHalfY = window.innerHeight / 2;
      this.camera.aspect = window.innerWidth / window.innerHeight;
      this.camera.updateProjectionMatrix();
      /* if (X < Y) {
                //this.renderer.setSize(window.innerWidth, window.innerHeight * 0.4);
                //this.camera.position.z = 700
            } else {
                //this.renderer.setSize(window.innerWidth, window.innerHeight * 0.75);
                this.camera.position.z = 350
            } */
    },
    animeOpen() {
      let X = window.innerWidth;
      let Y = window.innerHeight;
      let P = 130;
      if (Y > X) {
        P = 150 * (Y / X);
      } else {
        P = 130;
      }
      const tl = new gsap.timeline();
      tl.to(this.camera.position, {
        z: P,
        duration: 1.2,
        ease: "power2.out",
        onUpdate: this.render(),
      });

      //tl.play()
    },
    loadNext() {
      let p = this.page;
      p++;
      if (p > this.nbSlide) {
        p = 1;
      }
      this.page = p;

      this.animeChangePage();
    },
    loadPrevious() {
      let p = this.page;
      p--;
      if (p < 1) {
        p = this.nbSlide;
      }
      this.page = p;

      this.animeChangePage();
    },
    animeChangePage() {
      const tl = new gsap.timeline({ paused: true });
      tl.to(this.titre.position, {
        x: this.lngDeplace,
        duration: 0.5,
        ease: "power2.out",
        onComplete: () => {
          this.titre.position.x = -this.lngDeplace;
        },
      });
      tl.to(
        this.fond.position,
        {
          x: this.lngDeplace,
          duration: 1,
          ease: "power2.out",
          onComplete: () => {
            this.fond.position.x = -this.lngDeplace;
          },
        },
        "-=0.3"
      );
      tl.to(
        this.detail.position,
        {
          x: this.lngDeplace,
          duration: 0.5,
          ease: "power2.out",
          onComplete: () => {
            this.Init3D(true);
          },
        },
        "-=0.7"
      );

      tl.to(
        this.fond.position,
        { x: 0, duration: 1.5, ease: "power2.out" },
        "+=0.5"
      );
      tl.to(
        this.titre.position,
        { x: -60, duration: 1, ease: "power2.out" },
        "-=1.2"
      );

      tl.to(
        this.detail.position,
        { x: 70, duration: 1, ease: "power2.out" },
        "-=0.7"
      );
      tl.play();
    },
    ouvreProjet() {
      console.log(this.page);
      this.stopAnimation();
      switch (this.page) {
        case 1:
          break;
        case 2:
          console.log("page:" + 2);
          this.$router.push("/futureCity");
          break;
        case 3:
          break;
        case 4:
          break;
        case 5:
          break;
      }
    },
    stopAnimation() {
      console.log("cassé");
      cancelAnimationFrame(this.idAnim);
    },
    affDetailTechno() {
      let techno = document.querySelector(".navigation-2");
      let mh = getComputedStyle(techno, null).maxHeight;
      if (mh == "35px") {
        techno.style.maxHeight = "none";
      } else {
        techno.style.maxHeight = "35px";
      }
    },
  },
};
</script>

<style scoped lang="scss">
body {
  background-color: black;
}
.acceuil {
  text-align: center;
  position: relative;

  .prev {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    border-radius: 50%;
    background-color: cyan;
    width: 30px;
    height: 30px;
    top: 50%;
    left: 25px;
    font-size: 14px;
    z-index: 10;
    cursor: pointer;
    transition: 0.2s ease-in-out;
    &:hover {
      background-color: #f0f0f0;
    }
  }
  .suivant {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    position: absolute;
    border-radius: 50%;
    background-color: cyan;
    width: 30px;
    height: 30px;
    top: 50%;
    right: 25px;
    z-index: 10;
    cursor: pointer;
    transition: 0.2s ease-in-out;
    &:hover {
      background-color: #f0f0f0;
    }
  }
}

.navig {
  text-align: center;
  display: flex;
  width: 100%;
  justify-content: space-evenly;
  align-items: center;

  background-color: black;
  color: white;
  min-height: 35px;

  .info {
    width: 100px;
    height: 50px;
    font-size: 20px;
    font-weight: bold;
    padding: 0 120px;
    cursor: pointer;
    transition: 0.5s ease-in-out;
    .info-en {
      font-size: 12px;
      text-align: right;
    }
    &:hover {
      scale: 1.05;
    }
  }
}
.navigation-2 {
  text-align: center;
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: space-evenly;
  align-items: center;

  background-color: black;
  color: white;
  min-height: 35px;
  max-height: 35px;
  .btn {
    font-size: 16px;
    margin: 17px 0 0 0;

    cursor: pointer;
    transition: 0.2s ease-in-out;
    &:hover {
      scale: 1.05;
    }
  }
  .techno {
    margin: 30px 0 0 0;
    text-align: center;
    white-space: pre-wrap;
  }
}
@media screen and (max-width: 750px) {
  .entete {
    .titre {
      font-size: 0.8em;
    }
    .retour {
      font-size: 0.8em;
    }
  }
}
</style>
