<template>
  <div class="container" id="container">
    <div class="navigation">
      <div class="lien" @click="retour">Retour / Back</div>
    </div>

    <div class="projet" id="project1">
      <div class="titre">
        <span class="rouge">E</span>
        <span>x</span>
        <span>e</span>
        <span>m</span>
        <span>p</span>
        <span>l</span>
        <span>e</span>
      </div>
      <div class="titre">
        <span class="rouge">D</span>
        <span>e</span>
      </div>
      <div class="titre">
        <span class="rouge">P</span>
        <span>r</span>
        <span>o</span>
        <span>j</span>
        <span>e</span>
        <span>t</span>
        <span>s</span>
      </div>
      <!--<div class="titre">
        <span>R</span>
        <span>é</span>
        <span>c</span>
        <span>e</span>
        <span>n</span>
        <span>t</span>
        <span>s</span>
      </div>-->
      <div class="btnPosition">
        <div class="btn" id="btnAff" @click="deplaceCamera">Afficher</div>
      </div>
    </div>
    <div
      class="btnPrec btnRond nav2"
      v-show="caroussel_actif == true"
      @click="rotateSuivPrec(1)"
    >
      &#60;
    </div>
    <div
      class="btnSuiv btnRond nav2"
      v-show="caroussel_actif == true"
      @click="rotateSuivPrec(-1)"
    >
      &#62;
    </div>

    <div class="menu nav2">
      <div
        class="btnmenu"
        v-show="caroussel_actif == true"
        @click="chargeProjet"
      >
        Voir le projet
      </div>
      <div class="btnmenu" v-show="caroussel_actif == true" @click="affDetail">
        {{ txtInfos }}
      </div>
    </div>

    <div class="detail nav2" id="detail">
      Création d'un site Web pour une jeune photographe de vendée<br /><br />Site
      développé en vuejs 3 (cdn)
    </div>
  </div>
</template>

<script>
import * as THREE from "three";
import gsap from "gsap";
import * as FCT from "../js/functions";
import VueCookies from "vue-cookies";

export default {
  name: "project3d",
  data() {
    return {
      vitesseRotation: 0.005,
      caroussel_actif: false,
      position: 1,
      detail_actif: false,
      windowHalfX: 0,
      windowHalfY: 0,
      txtInfos: "+ d'infos",
      tmp3d: 2,
    };
  },
  async mounted() {
    // prechargment des images
    const tabImg = [
      "textures/photo1.png",
      "textures/photo2.png",
      "textures/crm.png",
      "textures/sn01.jpg",
      "textures/vitrine.jpg",
      "textures/vitrine.jpg",
    ];
    for (let i = 0; i < tabImg.length; i++) {
      await FCT.PreChargeImg(tabImg[i]);
      console.log("chgt img");
    }

    console.log("after");
    this.init3d();
    this.animate();

    if (this.$store.state.modeProjet == "complet") {
      this.tmp3d = 2;
      const tl = new gsap.timeline({ paused: true, delay: 1.2 });
      tl.to(".titre", { opacity: 1, duration: 0.2, stagger: 0.1 });
      tl.to("span", {
        rotateY: "0deg",
        opacity: 1,
        duration: 0.2,
        stagger: 0.1,
      });
      tl.to(".btn", { opacity: 1, duration: 0.5 });
      tl.to(".retour", { opacity: 1, duration: 0.5 });
      tl.play();
    } else {
      this.tmp3d = 0.7;
      this.deplaceCamera();
    }

    window.addEventListener("resize", this.onWindowResize);
    window.addEventListener("popstate", this.stopAnimation);
  },
  methods: {
    init3d() {
      this.scene = new THREE.Scene();
      //this.scene.fog = new THREE.FogExp2(0x000000, 0.004);
      this.camera = new THREE.PerspectiveCamera(
        75,
        window.innerWidth / window.innerHeight,
        0.1,
        10000
      );
      this.camera.lookAt(0, 0, 0);
      this.renderer = new THREE.WebGLRenderer({ antialias: true });
      let container = document.getElementById("container");
      this.renderer.setSize(window.innerWidth, window.innerHeight);
      this.renderer.setClearColor(0x000000, 1);

      container.appendChild(this.renderer.domElement);

      this.caroussel = new THREE.Group();

      //texture 1er cote du cube
      var texture = new THREE.TextureLoader().load("textures/progress.jpg");
      texture.wrapS = THREE.RepeatWrapping;
      texture.wrapT = THREE.RepeatWrapping;
      texture.repeat.set(1, 1);
      texture.needsUpdate = true;

      var material1 = new THREE.MeshBasicMaterial({
        color: 0xffffff,
        side: THREE.DoubleSide,
        map: texture,
        transparent: true,
      });
      material1.needsUpdate = true;

      let long = window.innerWidth / 8;
      if (long > 225) long = 225;
      if (long < 100) long = 100;
      let larg = long * 0.6;

      var geometry = new THREE.PlaneGeometry(long, larg, 10, 10);
      this.panel1 = new THREE.Mesh(geometry, material1);

      this.panel1.position.z += long / 2;
      this.caroussel.add(this.panel1);

      //texture 2eme cote du cube
      texture = new THREE.TextureLoader().load("textures/photo2.jpg");
      var material1 = new THREE.MeshBasicMaterial({
        color: 0xffffff,
        side: THREE.DoubleSide,
        map: texture,
        transparent: true,
      });
      this.panel2 = new THREE.Mesh(geometry, material1);
      this.panel2.position.x += long / 2;
      //this.panel2.position.z -= 100
      this.panel2.rotation.y = 0.5 * Math.PI;

      this.caroussel.add(this.panel2);

      //texture 3eme cote du cube
      texture = new THREE.TextureLoader().load("textures/crm.jpg");
      var material1 = new THREE.MeshBasicMaterial({
        color: 0xffffff,
        side: THREE.DoubleSide,
        map: texture,
        transparent: true,
      });
      this.panel3 = new THREE.Mesh(geometry, material1);
      this.panel3.position.z -= long / 2;
      this.panel3.rotation.y = Math.PI;

      this.caroussel.add(this.panel3);

      //texture 4eme cote du cube
      texture = new THREE.TextureLoader().load("textures/sn01.jpg");
      var material1 = new THREE.MeshBasicMaterial({
        color: 0xffffff,
        side: THREE.DoubleSide,
        map: texture,
        transparent: true,
      });
      this.panel4 = new THREE.Mesh(geometry, material1);
      this.panel4.position.x -= long / 2;
      //this.panel4.position.z -= 100
      this.panel4.rotation.y = -0.5 * Math.PI;

      this.caroussel.add(this.panel4);

      //texture dessus du cube
      var geometry2 = new THREE.PlaneGeometry(long, long, 10, 10);
      texture = new THREE.TextureLoader().load("textures/projet6.jpg");
      var material1 = new THREE.MeshBasicMaterial({
        color: 0xffffff,
        side: THREE.DoubleSide,
        map: texture,
        transparent: true,
      });
      this.panel5 = new THREE.Mesh(geometry2, material1);
      this.panel5.position.y += larg / 2;
      //this.panel4.position.z -= 100
      this.panel5.rotation.x = -0.5 * Math.PI;

      this.caroussel.add(this.panel5);

      //texture dessous du cube
      texture = new THREE.TextureLoader().load("textures/vitrine.jpg");
      var material1 = new THREE.MeshBasicMaterial({
        color: 0xffffff,
        side: THREE.DoubleSide,
        map: texture,
        transparent: true,
      });
      this.panel6 = new THREE.Mesh(geometry2, material1);
      this.panel6.position.y -= larg / 2;
      //this.panel4.position.z -= 100
      this.panel6.rotation.x = 0.5 * Math.PI;

      this.caroussel.add(this.panel6);

      this.scene.add(this.caroussel);

      this.pointlight = new THREE.PointLight(0xffffff, 1, 10000);
      this.pointlight.position.y = 0;
      this.pointlight.position.z = long;
      this.pointlight.castShadow = true;
      this.scene.add(this.pointlight);

      const directionalLight = new THREE.DirectionalLight(0xffffff, 0.5);
      directionalLight.position.set(-10, 10, 250);
      directionalLight.lookAt(0, 0, 0);
      this.scene.add(directionalLight);

      this.camera.position.z = 500;
      this.camera.position.y = -window.innerHeight / 4;
      this.camera.position.x = window.innerWidth / 4;
    },
    animate() {
      this.idAnim = requestAnimationFrame(this.animate);
      //this.camera.lookAt(this.scene.position);

      let vit = 0;
      //vit = parseFloat(vitesseRotation)
      this.caroussel.rotation.y -= this.vitesseRotation;

      this.renderer.render(this.scene, this.camera);
    },
    deplaceCamera() {
      console.log(this.caroussel.rotation.y);
      this.caroussel_actif = true;
      const tl = new gsap.timeline({ paused: true });
      tl.to(this.camera.position, {
        x: 0,
        y: 0,
        z: 250,
        duration: this.tmp3d,
        ease: "power2.easeOut",
      });
      tl.to(
        ".titre",
        { opacity: 0, duration: 0.8, ease: "power2.easeOut" },
        "-=1.5"
      );
      tl.to(
        ".btn",
        { opacity: 0, duration: 0.8, ease: "power2.easeOut" },
        "-=1.5"
      );
      tl.to(
        this,
        { vitesseRotation: 0, duration: 2, ease: "power2.easeOut" },
        "-=1.5"
      );
      tl.to(
        ".menu",
        { opacity: 1, duration: 0.5, ease: "power2.easeOut" },
        "-=1.5"
      );

      tl.to(this.caroussel.rotation, { y: 0, duration: 3 }, "-=1");
      tl.to(
        ".btnRond",
        { opacity: 1, duration: 0.5, ease: "power2.easeOut" },
        "-=2"
      );
      tl.play();
    },
    rotateSuivPrec(sens) {
      //sens: 1: precedent / -1: suivant
      this.detail_actif = false;
      const tl = new gsap.timeline({ paused: true });
      console.log(this.position);
      let rot = 0;
      switch (this.position) {
        case 1:
          if (sens == 1) {
            rot = 0.5 * Math.PI * sens;
            console.log(rot);
            tl.to(this.caroussel.rotation, { x: rot, duration: 0.8 });
            this.position = 6;
            this.ecritDetail(6);
          } else {
            rot = 0.5 * Math.PI * sens;
            console.log(rot);
            tl.to(this.caroussel.rotation, { y: rot, duration: 0.8 });
            this.position = 2;
            this.ecritDetail(2);
          }

          break;
        case 2:
          if (sens == 1) {
            rot = 0;
          } else {
            rot = 1 * Math.PI * sens;
          }
          console.log(rot);
          tl.to(this.caroussel.rotation, { y: rot, duration: 0.8 });
          if (sens == 1) {
            this.position = 1;
            this.ecritDetail(1);
          } else {
            this.position = 3;
            this.ecritDetail(3);
          }
          break;
        case 3:
          rot = 1.5 * Math.PI * sens;
          console.log(rot);
          tl.to(this.caroussel.rotation, { y: rot, duration: 0.8 });
          if (sens == 1) {
            this.position = 2;
            this.ecritDetail(2);
          } else {
            this.position = 4;
            this.ecritDetail(4);
          }
          break;
        case 4:
          if (sens == 1) {
            rot = 1 * Math.PI * sens;
            console.log(rot);
            tl.to(this.caroussel.rotation, { x: 0, y: rot, duration: 0.8 });
            this.position = 3;
            this.ecritDetail(3);
          } else {
            rot = 0.5 * Math.PI * sens;
            console.log(rot);
            tl.to(this.caroussel.rotation, { x: rot, y: 0, duration: 0.8 });
            this.position = 5;
            this.ecritDetail(5);
          }

          break;
        case 5:
          if (sens == 1) {
            rot = 0.5 * Math.PI * sens;
            let rot2 = 0.5 * Math.PI * sens;
            console.log(rot);
            tl.to(this.caroussel.rotation, { x: 0, y: rot2, duration: 0.8 });
            this.position = 4;
            this.ecritDetail(4);
          } else {
            rot = 1.5 * Math.PI * sens;
            console.log(rot);
            tl.to(this.caroussel.rotation, { x: rot, y: 0, duration: 0.8 });
            this.position = 6;
            this.ecritDetail(6);
          }

          break;
        case 6:
          if (sens == 1) {
            rot = 1.5 * Math.PI * sens;
            console.log(rot);
            tl.to(this.caroussel.rotation, { x: rot, y: 0, duration: 0.8 });
            this.position = 5;
            this.ecritDetail(5);
          } else {
            tl.to(this.caroussel.rotation, { x: 0, y: 0, duration: 0.8 });
            this.position = 1;
            this.ecritDetail(1);
          }

          break;
      }
      tl.play();
    },
    affDetail() {
      //this.detail_actif = !this.detail_actif;
      let opa = document.getElementById("detail").style.opacity;
      if (opa == 0) {
        opa = 1;
        setTimeout(() => {
          this.txtInfos = "- d'infos";
        }, 350);
      } else {
        opa = 0;
        setTimeout(() => {
          this.txtInfos = "+ d'infos";
        }, 350);
      }
      const tl = new gsap.timeline({ paused: true });
      tl.to(".detail", { opacity: opa, duration: 1, ease: "power2.easeIn" });
      tl.play();
    },
    ecritDetail(page) {
      let txt = "";
      switch (page) {
        case 1:
          txt =
            "Appli WEB de gestion de projets<br/><br/>Le FRONT est en VueJs3 (+ vue router et vueX)<br/>Le BACK est une API en nodejs<br/>La base de donnée est MongoDB";
          break;
        case 2:
          txt =
            "Exemple de différents projet utilisant la 3D. <br/>Plus précisement WebGL au travers de la librairie Three js";
          break;
        case 3:
          txt =
            "Création dune WEB-APP de gestion commerciale<br/><br/>Le FRONT est en vuejs3 (+ vue router et vueX)<br/>Le BACK est une API en nodejs<br/>La base de donnée est Mysql";
          break;
        case 4:
          txt =
            "Réseau social permettant de partager des photos...<br><br>Le FRONT est en vuejs3 (+ vue router et vueX)<br/>Le BACK est une API en nodejs<br/>La base de donnée est DynamoDB";
          break;
        case 5:
          txt = "Exemple de 2 sites vitrines";
          break;
        case 6:
          txt = "Page 6";
          break;
      }
      let detail = document.getElementById("detail");
      detail.innerHTML = txt;
    },
    chargeProjet() {
      switch (this.position) {
        case 1:
          this.$store.state.directory = "PROGRESS";
          this.$store.state.titreProject = "Atom - Progress";
          this.$store.state.nbSlide = 6;
          //VueCookies.set("project-infos", ["PROGRESS", "Atom - Progress", 6], "30min")
          localStorage.projectDirectory = "PROGRESS";
          localStorage.projectTitle = "Atom - Progress";
          localStorage.projectNbSlides = 6;
          //VueCookies.set("project-title", "Atom - Progress", "30min")
          //VueCookies.set("project-nbslides", "6", "30min")
          this.$router.push("/Projects");
          break;
        case 2:
          this.$router.push("/threeExample");
          break;
        case 3:
          /* this.$store.state.directory = "GC"
          this.$store.state.titreProject = "Atom - Gestion Commerciale"
          this.$store.state.nbSlide = 7 */
          localStorage.projectDirectory = "GC";
          localStorage.projectTitle = "Atom - Gestion Commerciale";
          localStorage.projectNbSlides = 7;
          this.$router.push("/Projects");
          break;
        case 4:
          /* this.$store.state.directory = "SN"
          this.$store.state.titreProject = "Atom - Social Network"
          this.$store.state.nbSlide = 4 */
          localStorage.projectDirectory = "SN";
          localStorage.projectTitle = "Atom - Social Network";
          localStorage.projectNbSlides = 4;
          this.$router.push("/Projects");
          //window.open('http://atomsn-01.s3-website.eu-west-3.amazonaws.com/','_blank')
          break;
        case 5:
          this.$router.push("/vitrine");
          break;
        case 6:
          this.$router.push("/other");
          break;
      }
    },
    onWindowResize(e) {
      this.windowHalfX = window.innerWidth / 2;
      this.windowHalfY = window.innerHeight / 2;

      this.camera.aspect = window.innerWidth / window.innerHeight;
      this.camera.updateProjectionMatrix();

      this.renderer.setSize(window.innerWidth, window.innerHeight);
    },
    retour() {
      /* if (this.$store.state.modeProjet == "complet") {
        const tl = new gsap.timeline({ paused: true, delay: 1 });
      tl.to("span", { opacity: 0, duration: 0.1, stagger: 0.1 });
      tl.to(".btn", { opacity: 0, duration: 0.3 });
      tl.to(".retour", { opacity: 0, duration: 0.3 }, "-=0.3");
      tl.to(this, {onComplete: () => {
        this.stopAnimation();
        this.$router.push("/Acceuil");
      }})
      tl.play();
      } */
      const tl = new gsap.timeline({ paused: true, delay: 0.5 });
      tl.to(this.camera.position, {
        x: -3000,
        y: 0,
        z: 1000,
        duration: 2,
        ease: "power2.easeOut",
      });
      tl.to("#project1", { opacity: 0, duration: 1 }, "-=1.5");
      tl.to(".nav2", { opacity: 0, duration: 1 }, "-=1.5");
      tl.to(this, {
        onComplete: () => {
          this.stopAnimation();
          this.$router.push("/Acceuil");
        },
      });
      tl.play();
    },
    stopAnimation() {
      console.log("cassé");
      cancelAnimationFrame(this.idAnim);
    },
  },
};
</script>

<style scoped lang="scss">
.container {
  position: relative;
  width: 100%;
  height: 100vh;

  .projet {
    color: white;
    font-size: 55px;
    font-weight: bold;
    width: 50%;
    height: 80%;
    position: absolute;
    top: 10%;
    right: 2%;

    .titre {
      margin: 0 0 50px 0;
      will-change: transform;
      opacity: 1;

      span {
        display: inline-block;
        transform-origin: center;
        transform: rotateY(90deg);
      }
      .rouge {
        font-size: 60px;
        color: red;
      }
    }

    .btnPosition {
      .btn {
        display: block;
        position: relative;
        margin: 100px 0 0 0;
        color: white;
        font-size: 40px;
        font-weight: bold;
        border: 2px solid white;
        padding: 0.5em;
        max-width: 200px;
        text-align: center;
        transition: 0.5s ease;
        cursor: pointer;
        opacity: 0;
        transition: 0.5s all ease;
        background-color: transparent;
        z-index: 1;
        overflow: hidden;

        &::before {
          position: absolute;
          top: 50%;
          left: -20%;
          transform: translate(-110%, -50%) rotateZ(25deg);
          content: "";
          width: 5%;
          height: 120%;
          background-color: rgb(242, 177, 177);

          transition: 0.5s all ease;
          z-index: -1;
          opacity: 1;
        }
        &:hover::before {
          left: 120%;
        }
      }
    }

    .svg-projet {
      width: 100%;
      height: 100%;
    }
  }

  .btnPrec {
    position: absolute;
    left: 20px;
    top: 55%;

    &:hover {
      background-color: white;
    }
  }

  .btnSuiv {
    position: absolute;
    right: 20px;
    top: 55%;

    &:hover {
      background-color: white;
    }
  }

  .menu {
    position: absolute;
    bottom: 10%;
    left: 50%;
    width: 40%;
    transform: translateX(-50%);
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    opacity: 0;

    .btnmenu {
      color: white;
      border: 2px solid white;
      padding: 0.5em;
      font-size: 25px;
      font-weight: 300;
      background-color: rgba(0, 0, 0, 0.2);
      cursor: pointer;
      transition: 0.5s ease;
      margin: 0 5px 0 0;

      &:hover {
        background-color: white;
        color: black;
        padding: 0.5em 1.2em;
        clip-path: polygon(79% 0, 100% 51%, 82% 100%, 0 100%, 0 0);
      }
    }

    /*.infos {
      color:white;
      border: 1px solid white;
      padding: 0.5em;
      font-size: 25px;
      font-weight: 300;
      background-color: rgba(0,0,0,0.2);
      cursor: pointer;
    }*/
  }
  .detail {
    position: absolute;
    background-color: rgba(0, 0, 0, 0.6);
    color: white;
    width: 40%;
    bottom: 20%;
    left: 50%;
    transform: translateX(-50%);
    border: 1px solid white;
    border-radius: 8px;
    padding: 0.3em;
    opacity: 0;

    .ferme_detail {
      width: 15px;
      height: 15px;
      position: relative;
      top: 0;
      right: 0;
      border: 1px solid white;
      cursor: pointer;
    }
  }

  .retour {
    font-size: 20px;
    color: white;
    border: white 1px solid;
    padding: 5px;
    position: absolute;
    right: 20px;
    top: 10px;
    cursor: pointer;
    opacity: 0;
    transition: 0.5s ease-in;

    &:hover {
      background-color: white;
      color: black;
    }
  }
}

@media screen and (max-width: 450px) {
  .container {
    .projet {
      font-size: 25px;
    }
    .btnPosition {
      .btn {
        font-size: 20px;
      }
    }
    .entete {
      .retour {
        font-size: 0.8em;
      }
    }

    #btnAff {
      font-size: 0.8em;
      padding: 0.2em 0.3em;
      width: 60%;
    }

    .menu {
      .btnmenu {
        font-size: 0.8em;
        padding: 0.2em 0.3em;
      }
    }
  }
}
</style>
