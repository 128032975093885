<template>
  <div class="page">
    <nav class="navigation">
      <div class="lien" @click="retour">
      Retour / Back
    </div>
    </nav>
    
    <div class="grandtitre">
      LA 3D DANS LES SITE WEB
    </div>
    <div class="container">
      <div class="tilt1" id="tilt1" @click="ouvreFC">
        <div class="image">
          <div class="titre">
            FUTURE CITY
          </div>
        </div>
        <div class="info">
          Découvrez la ville du futur et detruisez la dans ce projet...
        </div>
      </div>

      <div class="tilt2" id="tilt2" @click="ouvreGame">
        <div class="image">
          <div class="titre">
            FIRST 3D GAME
          </div>
        </div>
        <div class="info">
          Arrivrez vous à dépasser les 2000 points ?
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VanillaTilt from "vanilla-tilt";

export default {
  name: "threeExample",
  mounted() {
    const div1 = document.getElementById("tilt1");

    VanillaTilt.init(div1, {
      glare: true, // if it should have a "glare" effect
      "max-glare": 1,
      scale: 1.1,
    });

    const div2 = document.getElementById("tilt2");

    VanillaTilt.init(div2, {
      glare: true, // if it should have a "glare" effect
      "max-glare": 1,
      scale: 1.1,
    });
  },
  methods: {
    ouvreFC() {
      this.$router.push("/futureCity");
    },
    ouvreGame() {
      this.$router.push("/game3d");
    },
    retour() {
      this.$store.state.modeProjet = "rapide"
      this.$router.push("/project3d");
    },
  },
};
</script>

<style scoped lang="scss">
.page {
  width: 100%;
  height: 100vh;
  background-color: rgb(26, 26, 26);

  .grandtitre {
    font-size: 30px;
    font-weight: 500;
    color: white;
    text-align: center;
    margin: 0 0 100px 0;
    padding: 20px 0 0 0;
    background-color: rgb(0, 0, 0);
  }

  .container {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;

    .tilt1 {
      position: relative;
      width: 250px;
      height: 450px;
      margin: 50px;
      background-color: blueviolet;
      transform-style: preserve-3d;
      transform: perspective(1000px);
      cursor: pointer;
      -webkit-box-shadow: 5px 5px 8px 6px rgba(0, 0, 0, 0.59);
      box-shadow: 5px 5px 8px 6px rgba(0, 0, 0, 0.59);

      .image {
        height: 100%;
        width: 100%;
        background-image: url("../../public/textures/fc1.jpg");
        position: relative;
      }

      .titre {
        font-size: 25px;
        font-weight: 400;
        text-align: center;
        position: relative;
        width: 100%;
        height: 35px;
        top: 50%;
        border-top: 1px solid white;
        z-index: 1;
        background-color: rgba(255, 255, 255, 0.4);
        backdrop-filter: blur(2px);
      }

      .info {
        position: absolute;
        width: 70%;
        transform: translateZ(35px) translateX(-50%);
        bottom: 15px;
        left: 50%;
        background-color: rgba(0, 0, 0, 0.5);
        color: white;
        text-align: center;
      }
    }

    .tilt2 {
      position: relative;
      width: 250px;
      height: 450px;
      margin: 50px;
      background-color: blueviolet;
      transform-style: preserve-3d;
      transform: perspective(1000px);
      cursor: pointer;
      -webkit-box-shadow: 5px 5px 8px 6px rgba(0, 0, 0, 0.59);
      box-shadow: 5px 5px 8px 6px rgba(0, 0, 0, 0.59);
      .image {
        height: 100%;
        width: 100%;
        background-image: url("../../public/textures/g1.jpg");
        position: relative;
      }

      .titre {
        font-size: 25px;
        font-weight: 400;
        text-align: center;
        position: relative;
        width: 100%;
        height: 35px;
        top: 50%;
        border-top: 1px solid white;
        z-index: 1;
        background-color: rgba(255, 255, 255, 0.4);
        backdrop-filter: blur(2px);
      }

      .info {
        position: absolute;
        width: 70%;
        transform: translateZ(35px) translateX(-50%);
        bottom: 15px;
        left: 50%;
        background-color: rgba(0, 0, 0, 0.5);
        color: white;
        text-align: center;
      }
    }
  }

  .retour {
  font-size: 20px;
  color: white;
  border: white 1px solid;
  padding: 5px;
  position: absolute;
  right: 20px;
  top: 10px;
  cursor: pointer;
  opacity: 1;
  transition: 0.5s ease-in;

  &:hover {
    background-color: white;
    color: black;
  }
}
}

@media screen and (max-width: 600px) {
  
  

  .entete {
    
    .retour {
      font-size: 0.8em;
    }
  }
}
</style>
