<template>
  <div
    class="acceuil"
    id="acceuil"
    @mousemove="onPointerMove"
    @touchmove="onPointerMove"
  >
    <!--<div class="menu">
            <div class="menu-txt">
                Mes Compétences
            </div>
             <div class="menu-txt">
                Mes Projects
            </div>
             <div class="menu-txt">
                About Me
            </div>
        </div>
        <div class="detail">

        </div>-->

    <nav class="navigation">
      <div class="lien" @click="ouvreContact">
        Contact
      </div>
      <div class="lien" @click="retour">
        Retour / Back
      </div>
    </nav>

    <div class="m1 menu" @click="deplaceProjet">
      MES PROJETS
      <div class="menu-en">
        My projects
      </div>
    </div>

    <div class="m2 menu" @click="deplaceComp">
      MES COMPETENCES
      <div class="menu-en">
        My skills
      </div>
    </div>

    <div class="m3 menu" @click="deplaceAbout">
      A PROPOS
      <div class="menu-en">
        About
      </div>
    </div>

    <!-- <div class="enCharge" v-if="enCharge == true">

  </div> -->
  </div>
</template>

<script>
import gsap from "gsap";
import router from "../router";
import * as THREE from "three";
import { BoxGeometry } from "three";
import VueCookies from "vue-cookies";
import * as FCT from "../js/functions";

export default {
  name: "Acceuil",
  data() {
    return {
      mouseX: 0,
      mouseY: 0,
      windowHalfX: 0,
      windowHalfY: 0,
      cx: 0,
      cy: 0,
      zDepart: 800,
      color: 0x8e150d,
      enCharge: false,
    };
  },
  mounted() {
    const C1 = VueCookies.get("atomColor");

    this.color = FCT.trouveColor(C1);

    this.windowHalfX = window.innerWidth / 2;
    this.windowHalfY = window.innerHeight / 2;

    this.init3D();
    this.render();
    //this.animate(true)
    //this.modifFog()
    window.addEventListener("resize", this.onWindowResize);
    window.addEventListener("touchmove", this.render);
    window.addEventListener("popstate", this.stopAnimation);

    const tl = new gsap.timeline();
    //tl.to(this.camera.position, {z:800, duration:0.8})
    //this.animate(false)
    tl.to(".m1", { opacity: 1, scale: 1.2, duration: 0.5 }, "+=0.5");
    tl.to(".m2", { opacity: 1, scale: 1.2, duration: 0.5 });
    tl.to(".m3", { opacity: 1, scale: 1.2, duration: 0.5 });
    tl.to(".retour", { opacity: 1, scale: 1.1, duration: 0.5 }, "+=0.2");
    tl.to(".contact", { opacity: 1, scale: 1.1, duration: 0.5 }, "+=0.2");
  },
  methods: {
    init3D() {
      this.scene = new THREE.Scene();
      this.scene.fog = new THREE.FogExp2(0xffffff, 0.0002);
      this.camera = new THREE.PerspectiveCamera(
        75,
        window.innerWidth / window.innerHeight,
        0.1,
        1000
      );
      this.camera.lookAt(0, 0, 0);
      this.renderer = new THREE.WebGLRenderer({ antialias: true });
      this.renderer.setSize(window.innerWidth, window.innerHeight);
      let acceuil = document.getElementById("acceuil");
      acceuil.appendChild(this.renderer.domElement);
      this.windowHalfX = window.innerWidth / 2;
      this.windowHalfY = window.innerHeight / 2;

      /*
            var geometry = new THREE.TorusGeometry( 1, 0.5, 16, 100 );
            var material = new THREE.MeshBasicMaterial( { color: 0x000fff } );
            this.cube = new THREE.Mesh( geometry, material );
            this.scene.add( this.cube );*/

      this.camera.position.z = 1500;
      this.camera.position.x = 0;
      this.camera.position.y = 0;

      //var geometry = new THREE.BufferGeometry();
      var geometry = new THREE.BoxGeometry(50, 50, 50, 2, 2, 2);

      const loader = new THREE.TextureLoader();
      const texture = loader.load("textures/metal1.jpg");

      //var material = new THREE.MeshBasicMaterial( {color: 0x0000ff, wireframe: true} )
      var material = new THREE.MeshStandardMaterial({
        metalness: 0.9,
        roughnessMap: texture,
        roughness: 0.15,
      });

      //const vertices = [];

      //const sprite = new THREE.TextureLoader().load( '/textures/circle.png' );

      for (let i = 0; i < 200; i++) {
        const x = 2000 * Math.random() - 1000;
        const y = 2000 * Math.random() - 1000;
        const z = 1600 * Math.random() - 600;

        const cube = new THREE.Mesh(geometry, material);
        cube.position.x = x;
        cube.position.y = y;
        cube.position.z = z;

        this.scene.add(cube);

        //ajout des lumieres
        if (i % 20 == 0) {
          //console.log(z);
          const light = new THREE.PointLight(this.color, 15, 500);
          //light.add( new THREE.Mesh( sphere, new THREE.MeshBasicMaterial( { color: 0xff0040 } ) ) );
          light.position.set(x - 120, y + 120, z + 120);
          this.scene.add(light);
        }

        //vertices.push(x, y, z);
      }
      /*
            geometry.setAttribute(
            "position",
            new THREE.Float32BufferAttribute(vertices, 3)
            );

            var material = new THREE.PointsMaterial({
            size: 3,
            sizeAttenuation: true,
            alphaTest: 0.5,
            transparent: true,
            });
            //material.color.setHSL(1.0, 0.3, 0.7);
            material.color.setHSL(1.0, 0.1, 0.5);

            const particles = new THREE.Points(geometry, material);
            this.scene.add(particles);
*/
      //this.camera.lookAt( this.scene.position );
      //this.renderer.render(this.scene, this.camera);
      this.render();
      
    },
    onPointerMove(event) {
      event.preventDefault();
      if (event.isPrimary === false) return;

      this.mouseX = event.clientX - this.windowHalfX;
      this.mouseY = event.clientY - this.windowHalfY;
      this.render();
    },
    animate(active) {
      /*
				if(active==true) {
                    console.log('anim on')
                    requestAnimationFrame( this.animate(true) );
                } else {
                    console.log('anim off')
                    cancelAnimationFrame( this.animate(false) )
                }
                */
      //console.log("animate");
      this.render();
    },
    render() {
      //const time = Date.now() * 0.00005;

      let x1 = this.mouseX; //- parseFloat( this.camera.position.x)
      let y1 = this.mouseY; //parseFloat( this.mouseY - this.camera.position.y)

      if (x1 == undefined) {
        x1 = 0;
      }
      if (y1 == undefined) {
        y1 = 0;
      }

      // console.log("x: " + x1 + "  -y:" + y1);
      this.camera.position.x = x1 * 0.8; //* 0.5;
      this.camera.position.y = y1 * 0.8; //* 0.5;
      //this.camera.position.z = Math.abs(x1) * 0.1
      this.cx = this.camera.position.x;
      this.cy = this.camera.position.y;

      //console.log('cx: ' + this.camera.position.x + "  - cY: " + this.camera.position.y)
      //console.log(this.mouseX + "  -  " + this.camera.position.x)

      this.camera.lookAt(this.scene.position);

      //const h = ( 360 * ( 1.0 + time ) % 360 ) / 360;
      //material.color.setHSL( h, 0.5, 0.5 );

      //console.log(this.mouseX - this.camera.position.x)

      this.renderer.render(this.scene, this.camera);
    },
    deplaceProjet() {
      this.$store.state.modeProjet = "complet"
      const tl = new gsap.timeline();
      tl.to(".m2", { opacity: 0, duration: 0.5 });
      tl.to(".m3", { opacity: 0, duration: 0.5 }, "-=0.5");
      tl.to(
        ".m1",
        {
          top: "45vh",
          left: "38vw",
          scale: 1.5,
          duration: 1.5,
          ease: "power2.out",
        },
        "-=1"
      );
      tl.to(".m1", { scale: 6, duration: 0.5, ease: "power2.out" });
      tl.to(".m1", { opacity: 0, duration: 0.5, ease: "power2.out" }, "-=0.5");
      tl.to(".m1", {
        onComplete: function() {
          this.enCharge = true;
          cancelAnimationFrame(this.idAnim);
          router.push("/project3d");
        },
      });
      //this.stopAnimation()
    },
    deplaceComp() {
      const tl = new gsap.timeline();
      tl.to(".m1", { opacity: 0, duration: 0.5 });
      tl.to(".m3", { opacity: 0, duration: 0.5 }, "-=0.5");
      //tl.to('.m2', {top: '40vh', left:'38vw',scale: 1.5, duration: 1.5, ease: 'power2.out'}, '-=1')
      tl.to(".m2", { scale: 6, duration: 0.8, ease: "power2.out" }, "-=0.3");
      tl.to(".m2", { opacity: 0, duration: 0.5, ease: "power2.out" }, "-=0.5");
      tl.to(".m2", {
        onComplete: function() {
          cancelAnimationFrame(this.idAnim);
          router.push("/Competence");
        },
      });
      //this.stopAnimation()
    },
    deplaceAbout() {
      const tl = new gsap.timeline();
      tl.to(".m1", { opacity: 0, duration: 0.5 });
      tl.to(".m2", { opacity: 0, duration: 0.5 }, "-=0.5");
      tl.to(
        ".m3",
        {
          top: "45vh",
          left: "38vw",
          scale: 1.5,
          duration: 1.5,
          ease: "power2.out",
        },
        "-=1"
      );
      tl.to(".m3", { scale: 6, duration: 0.5, ease: "power2.out" });
      tl.to(".m3", { opacity: 0, duration: 0.5, ease: "power2.out" }, "-=0.5");
      tl.to(".m3", {
        onComplete: function() {
          this.enCharge = true;
          cancelAnimationFrame(this.idAnim);
          router.push("/About");
        },
      });
      //this.stopAnimation()
    },
    OuvreProjet() {
      console.log("ouvre p");
    },
    modifFog() {
      console.log("fog");
      for (let i = 1; i >= 0.0002; i -= 0.01) {
        //console.log(i);
        this.scene.fog = new THREE.FogExp2(0x000000, i);
        this.renderer.render(this.scene, this.camera);
      }
      this.scene.fog = new THREE.FogExp2(0x000000, 0.0002);
      this.renderer.render(this.scene, this.camera);
    },
    onWindowResize(e) {
      this.windowHalfX = window.innerWidth / 2;
      this.windowHalfY = window.innerHeight / 2;

      this.camera.aspect = window.innerWidth / window.innerHeight;
      this.camera.updateProjectionMatrix();

      this.renderer.setSize(window.innerWidth, window.innerHeight);
    },
    retour() {
      this.stopAnimation();
      router.push("/");
    },
    ouvreContact() {
      this.stopAnimation();
      router.push("/contact");
    },
    stopAnimation() {
      console.log("cassé");
      cancelAnimationFrame(this.idAnim);
    },
  },
};
</script>

<style scoped lang="scss">
body {
  background-color: black;
}

.retour {
  font-size: 20px;
  color: white;
  border: white 1px solid;
  padding: 5px;
  position: absolute;
  right: 20px;
  top: 10px;
  cursor: pointer;
  opacity: 0;
  transition: 0.5s ease-in;
  clip-path: polygon(73% 0, 100% 0, 100% 86%, 35% 100%, 0 100%, 0 15%);

  &:hover {
    background-color: white;
    color: black;
    clip-path: none;
  }
}

.contact {
  font-size: 20px;
  color: white;
  border: white 1px solid;
  padding: 5px;
  position: absolute;
  left: 20px;
  top: 10px;
  cursor: pointer;
  opacity: 0;
  transition: 0.5s ease-in;

  &:hover {
    background-color: white;
    color: black;
  }
}

.acceuil {
  width: 100%;
  height: 100vh;
  background-color: black;
  overflow: hidden;
}

.menu {
  background-color: transparent;
  padding: 5px;
  font-size: 40px;
  color: white;
  position: absolute;
  cursor: pointer;
  opacity: 0;

  .menu-en {
    font-size: 15px;
    padding: 15px 15px;
    text-align: right;
  }
}

.m1 {
  top: 10%;
  left: 10%;
  transition: 0.25s ease-in;

  &:hover {
    scale: 1.2;
  }
}

.m2 {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: 0.25s ease-in;

  &:hover {
    scale: 1.2;
  }
}

.m3 {
  bottom: 10%;
  right: 10%;
  transition: 0.25s ease-in;

  &:hover {
    scale: 1.2;
  }
}

.detail {
  flex-basis: 80%;
  background-color: black;
}

.enCharge {
  position: absolute;
  width: 300px;
  height: 165px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: url("../../public/img/loader.gif");
}

@media screen and (max-width: 500px) {
  .menu {
    font-size: 25px;
  }

  .entete {
    .titre {
      font-size: 0.8em;
    }
    .retour {
      font-size: 0.8em;
    }
  }
}
</style>
