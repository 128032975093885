<template>
  <div class="acceuil" id="acceuil">
    <nav class="navigation">
      <div class="lien" @click="retour">Retour / Back</div>
    </nav>

    <div class="infosAtom">
      <p><span>A</span>tom-Dev</p>
      <p><span>V</span>incent Chanut</p>
      <p><span>T</span>el: 06.22.14.29.20</p>
      <p><span>M</span>ail: contact@atom-dev.net</p>
      <div
        class="btn"
        id="btn_form"
        style="max-width: 220px"
        @click="valideInfos"
      >
        Formulaire de contact
      </div>
    </div>
    <div class="infos-container">
      <div class="infos">
        <div class="erreur" v-if="msgErr != ''">{{ msgErr }}</div>

        <input type="text" placeholder="nom / name" v-model="nom" />

        <input
          type="text"
          placeholder="Prénom / firstname"
          v-model="prenom"
          required
        />

        <input type="text" placeholder="mail" v-model="mail" required />
        <input type="text" placeholder="Téléphone" v-model="tel" />
        <p style="color: #f0f0f0">____________________________</p>
        <label for="projet" style="color: #f0f0f0">Votre projet</label>

        <select name="projet" id="projet" v-model="projet">
          <option value=""></option>
          <option value="Création d'un Site Web">Création d'un Site Web</option>
          <option value="Modification d'un Site Web">
            Modification d'un Site Web
          </option>
          <option value="Création d'un Site e-Commerce">
            Création d'un Site e-Commerce
          </option>
          <option value="Modification d'un Site e-Commerce">
            Modification d'un Site e-Commerce
          </option>
          <option value="Création d'une API">Création d'une API</option>
          <option value="Création d'une Application WEB">
            Création d'une Application WEB (full-stack)
          </option>
          <option value="Création application Windows">
            Création application Windows (C# ou vb.net)
          </option>
          <option value="Conseil en entreprise">
            Conseil en entreprise (Choix technologie ou ERP/GPAO)
          </option>
          <option value="autre">autre ...</option>
        </select>

        <textarea
          name="detail"
          id="detail"
          rows="6"
          placeholder="detail..."
          v-model="detail"
        ></textarea>

        <div class="btn" @click="sendMail" v-if="envoieEnCours == false">
          Envoyer
        </div>
        <div
          v-else
          style="
            text-align: center;
            font-weight: 600;
            font-size: 1.3em;
            margin: 15px 0 5px 0;
            color: #fff;
          "
        >
          Envoie en cours ...
        </div>
      </div>
    </div>

    <div class="mail-container">
      <div class="infos">
        <!--         <button @click="valideMail">Suivant/Next</button>
 -->
      </div>
    </div>

    <div class="project-container">
      <div class="infos">
        <!--         <button @click="valideContact">Suivant/Next</button>
 -->
      </div>
    </div>

    <div class="contactConfirm">CONTACT CONFIRMED</div>
  </div>
</template>

<script>
import * as THREE from "three";
import gsap from "gsap";
import VueCookies from "vue-cookies";
import * as FCT from "../js/functions";
import axios from "axios";

export default {
  name: "contact",
  data() {
    return {
      nom: "",
      prenom: "",
      mail: "",
      projet: "",
      tel: "",
      detail: "",
      moveZ: 0,
      moveY: 0,
      moveX: 0,
      windowHalfX: 0,
      windowHalfY: 0,
      color: 0xc42020,
      msgErr: "",
      envoieEnCours: false,
    };
  },
  mounted() {
    const C1 = VueCookies.get("atomColor");

    this.color = FCT.trouveColor(C1);

    this.init3D();
    this.animate();

    let tl = new gsap.timeline({ paused: false, delay: 1 });
    tl.to(this.camera.position, {
      z: 850,
      y: 250,
      duration: 4,
      ease: "power2.out",
    });
    tl.to(".retour", { opacity: 1, duration: 0.5, ease: "power2.out" });
    tl.to(
      "#btn_form",
      { opacity: 1, duration: 0.5, ease: "power2.out" },
      "-=0.5"
    );

    window.addEventListener("resize", this.onWindowResize);
    window.addEventListener("popstate", this.stopAnimation);
  },
  methods: {
    init3D() {
      this.scene = new THREE.Scene();
      this.scene.fog = new THREE.FogExp2(this.color, 0.005);
      this.camera = new THREE.PerspectiveCamera(
        75,
        window.innerWidth / window.innerHeight,
        0.1,
        1000
      );
      //this.camera.lookAt(0, 0, 0);
      this.renderer = new THREE.WebGLRenderer({ antialias: true });
      let acceuil = document.getElementById("acceuil");
      this.renderer.setSize(window.innerWidth, window.innerHeight);
      this.renderer.setClearColor(0x000000, 1);

      const loader = new THREE.TextureLoader();
      const hauteur = loader.load("textures/nuage3.png");
      //const texture = loader.load("textures/mars3.jpg");
      const texture = hauteur;
      let geometry = new THREE.PlaneBufferGeometry(2500, 2500, 400, 400);

      //console.log(geometry.attributes.uv);
      let material = new THREE.MeshStandardMaterial({
        color: this.color,
        map: texture,
        displacementMap: hauteur,
        displacementScale: 250,
        opacity: 0.9,
        metalness: 0.5,
        wireframe: true,
      });
      //let material = new THREE.MeshStandardMaterial({color: 'ff0000'})

      this.plane = new THREE.Mesh(geometry, material);
      this.plane.rotation.x = 4.6;
      this.plane.position.y = -1;

      this.pointlight = new THREE.PointLight(this.color, 1.5);
      this.pointlight.position.y = 300;
      this.pointlight.position.z = 200;
      this.scene.add(this.pointlight);
      //const light = new THREE.AmbientLight( 0x404040 ); // soft white light
      //scene.add( light );

      this.scene.add(this.plane);

      for (let i = 0; i <= 90; i++) {
        let dimB = Math.random() * 5 + 1;
        //console.log(dimB);
        const geomCube = new THREE.BoxGeometry(dimB, dimB, dimB);
        const materialCube = new THREE.MeshStandardMaterial({
          color: this.color,
          metalness: 0.9,
          roughness: 0.1,
        });
        const cube = new THREE.Mesh(geomCube, materialCube);

        let posX = Math.random() * 1250 - 625;
        let posZ = Math.random() * 2500 - 700;
        let posY = Math.random() * 50 + 250;

        cube.position.set(posX, posY, posZ);

        this.scene.add(cube);
      }

      const geomContact = new THREE.TorusKnotGeometry(25, 5, 100, 16);
      this.materialCubeContact = new THREE.MeshStandardMaterial({
        color: 0xdddddd,
        map: texture,
        metalness: 0.9,
        roughness: 0.1,
        opacity: 0,
        transparent: true,
      });
      this.cubeContact = new THREE.Mesh(geomContact, this.materialCubeContact);

      this.cubeContact.position.y = 125;
      this.cubeContact.position.z = -360;
      this.cubeContact.position.x = -20;
      this.scene.add(this.cubeContact);

      this.pointlight2 = new THREE.PointLight(this.color, 0.5);
      this.pointlight2.position.y = 125;
      this.pointlight2.position.z = -250;
      this.pointlight2.position.x = -20;
      this.scene.add(this.pointlight2);

      this.camera.position.z = 1000;
      this.camera.position.y = 520;

      acceuil.appendChild(this.renderer.domElement);

      this.renderer.render(this.scene, this.camera);
    },
    animate() {
      this.idAnim = requestAnimationFrame(this.animate);
      // console.log(this.idAnim)
      /*let x1 = this.mouseX //- parseFloat( this.camera.position.x)
            let y1 = this.mouseY //parseFloat( this.mouseY - this.camera.position.y)

            if (x1 == undefined) { x1 = 1 }
            if (y1 == undefined) { y1 = 1 }

            //console.log('x: ' + x1 + '  -y:' + y1)
			this.camera.position.x = x1 * 0.015 //* 0.5;
			this.camera.position.y = y1 * 0.015 //* 0.5;*/
      //this.plane.rotation.x += 0.001
      //console.log(this.plane.rotation.x)
      //this.camera.position.z -= 0.01
      //console.log(this.camera.position.x + ' - ' + this.camera.position.y + '  - ' + this.camera.position.z)

      this.cubeContact.rotation.y += 0.01;
      this.cubeContact.rotation.x += 0.01;
      this.pointlight.position.set(
        this.camera.position.x,
        this.camera.position.y + 50,
        this.camera.position.z + 1
      );

      //this.camera.lookAt( this.scene.position );
      this.renderer.render(this.scene, this.camera);
    },
    valideInfos() {
      let tl = new gsap.timeline({ paused: true });
      tl.to(this.camera.position, {
        z: 320,
        y: 240,
        duration: 2,
        ease: "power2.out",
      });
      tl.to(
        ".infos-container",
        { opacity: 1, top: "275px", duration: 0.5 },
        "-=1.5"
      );
      tl.to(
        "#btn_form",
        { opacity: 0, duration: 0.5, ease: "power2.out" },
        "-=0.5"
      );

      tl.to(this.materialCubeContact, {
        opacity: 1,
        duration: 4,
        ease: "power2.in",
      });
      tl.play();
    },
    Envoyer() {
      let tl = new gsap.timeline({ paused: true });
      tl.to(this.camera.position, { z: 45, duration: 3, ease: "power2.in" });

      tl.to(
        this.camera.position,
        { y: 190, duration: 3, ease: "power2.in" },
        "-=3"
      );
      /* tl.to(this.camera.position, { z: 10, duration: 2, ease: "power2.out" });
      tl.to(
        this.camera.position,
        { y: 150, duration: 3, ease: "power2.out" },
        "-=1"
      ); */
      tl.to(
        ".infos-container",
        { opacity: 0, duration: 1, ease: "power2.out" },
        "-=3"
      );
      tl.to(
        ".infosAtom",
        { opacity: 0, duration: 1, ease: "power2.out" },
        "-=2"
      );
      tl.to(this.camera.position, {
        z: -250,
        y: 120,
        duration: 3,
        ease: "power2.out",
      });
      /* tl.to(
        this.camera.position,
        { y: 120, duration: 3, ease: "power2.out" },
        "-=3"
      ); */

      tl.to(
        ".contactConfirm",
        { opacity: 1, duration: 1, ease: "power2.out" },
        "+=0.5"
      );
      tl.to(
        this.cubeContact.position,
        { y: 350, duration: 1, ease: "power2.out" },
        "+=0.5"
      );
      tl.to(".contactConfirm", { opacity: 0, duration: 0.6 });
      tl.to(this.camera.position, {
        z: -450,
        y: 450,
        duration: 1,
        ease: "power2.in",
        onComplete: () => {
          cancelAnimationFrame(this.idAnim);
          this.$router.push("/");
        },
      });
      tl.play();
    },
    valideContact() {
      let tl = new gsap.timeline({ paused: true });
      tl.to(this.camera.position, { z: -250, duration: 3, ease: "power2.out" });
      tl.to(
        this.camera.position,
        { y: 120, duration: 3, ease: "power2.out" },
        "-=3"
      );
      tl.to(
        ".mail-container",
        { opacity: 0, duration: 1, ease: "power2.out" },
        "-=3"
      );
      tl.to(
        ".project-container",
        { opacity: 0, duration: 1, ease: "power2.out" },
        "-=1"
      );
      tl.to(
        ".contactConfirm",
        { opacity: 1, duration: 1, ease: "power2.out" },
        "+=0.5"
      );
      tl.to(".contactConfirm", { opacity: 0, duration: 0.6 }, "+=1");
      tl.to(this.camera.position, {
        z: -450,
        duration: 1,
        ease: "power2.in",
        onComplete: () => {
          cancelAnimationFrame(this.idAnim);
          this.$router.push("/");
        },
      });

      tl.play();
    },
    onWindowResize(e) {
      this.windowHalfX = window.innerWidth / 2;
      this.windowHalfY = window.innerHeight / 2;

      this.camera.aspect = window.innerWidth / window.innerHeight;
      this.camera.updateProjectionMatrix();

      this.renderer.setSize(window.innerWidth, window.innerHeight);
    },
    stopAnimation() {
      console.log("cassé");
      cancelAnimationFrame(this.idAnim);
    },
    retour() {
      const tl = new gsap.timeline({ paused: true, delay: 0.5 });
      tl.to(".infosAtom", {
        opacity: 0,
        top: "-500px",
        duration: 1,
        stagger: 0.1,
      });
      tl.to(".infos-container", { opacity: 0, left: "-1000px", duration: 1 });
      tl.to(".retour", { opacity: 0, duration: 0.3 }, "-=0.3");
      tl.to(this.camera.position, {
        y: 1200,
        z: 1200,
        left: "-1000px",
        duration: 2.5,
      });
      tl.to(this, {
        onComplete: () => {
          this.stopAnimation();
          this.$router.push("/");
        },
      });
      tl.play();
    },

    sendMail() {
      this.msgErr = "";
      let msg = "";

      if (this.mail == "" || this.mail == undefined) {
        msg = " - email";
      }
      if (this.prenom == "" || this.prenom == undefined) {
        msg += " - prénom";
      }
      if (this.projet == "" || this.projet == undefined) {
        msg += " - projet";
      }

      if (msg != "") {
        this.msgErr = "Les champs suivant sont ogligatoires: " + msg;
        return;
      }

      this.envoieEnCours = true;

      let msgHTML = `
        <h3 style="margin: 20px 0 10px 0; border: 1px solid blue; padding: 10px">Demande de contact</h3>
        <h4 style="margin: 0 0 35px 10px">Reçu depuis le portfolio</h4>
        <TABLE style="padding: 6px;  border-bottom: 1px solid #ddd; width: 80%">
          <tr style="padding: 16px;  border-bottom: 1px solid #ddd;"><td> Email </td><td> ${this.mail} </td></tr>
          <tr style="padding: 16px;  border-bottom: 1px solid #ddd;"><td> Nom </td><td> ${this.nom} </td></tr>
          <tr style="padding: 16px;  border-bottom: 1px solid #ddd;"><td> Prenom </td><td> ${this.prenom} </td></tr>
          <tr style="padding: 16px;  border-bottom: 1px solid #ddd;"><td> Tel. </td><td> ${this.tel} </td></tr>
          <tr style="padding: 16px;  border-bottom: 1px solid #ddd;"><td> Projet </td><td> ${this.projet} </td></tr>
          <tr style="padding: 16px;  border-bottom: 1px solid #ddd;"><td> Detail </td><td style="whitespace: pre-wrap"> ${this.detail} </td></tr>
        </TABLE>
      `;
      console.log("msg: " + msgHTML);

      axios({
        method: "post",
        url: "https://www.atom-dev.net/sendmail/AtomMailSender",
        //url: "http://localhost:4444/AtomMailSender",
        data: {
          from: this.mail,
          to: "contact@atom-dev.net",
          subject: "demande de contact",
          text: "depuis le portfolio",
          html: msgHTML,
        },
      })
        .then((rep) => {
          console.log(rep);
          if (rep.data.status == "OK") {
            this.Envoyer();
          } else {
            this.msgErr = "Une erreur est survenue durant l'envoie du mail !";
            this.envoieEnCours = false;
          }
        })
        .catch((err) => {
          this.msgErr = "Une erreur est survenue durant l'envoie du mail !";
          console.log(err);
          this.envoieEnCours = false;
        });
    },
  },
};
</script>

<style scoped lang="scss">
body {
  background-color: black;
}

.retour {
  font-size: 20px;
  color: white;
  border: white 1px solid;
  padding: 5px;
  position: absolute;
  right: 20px;
  top: 10px;
  cursor: pointer;
  opacity: 0;
  transition: 0.5s ease-in;

  &:hover {
    background-color: white;
    color: black;
  }
}

.acceuil {
  width: 100%;
  height: 100vh;
  background-color: gray;
  overflow: hidden;

  .infosAtom {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: absolute;
    width: 100%;
    top: 50px;
    left: 50%;
    transform: translateX(-50%);
    border: none;
    text-align: center;
    color: white;
    opacity: 0.9;
    transition: all 0.5s ease;
    background-color: rgba(0, 0, 0, 0.329);
    p {
      padding: 5px 30px 0 0;
      margin: 5px 0;
      span {
        color: red;
        font-size: 1.3em;
        padding: 0 0 0 30px;
      }
    }

    &:hover {
      opacity: 1;
    }
  }

  .infos-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 33%;
    min-width: 300px;
    position: absolute;
    top: -800px;
    left: 50%;
    transform: translateX(-50%);
    padding: 10px;
    background-color: rgba(0, 0, 0, 0.4);
    border-radius: 5%;
    transition: top 2s ease-out;

    .infos {
      input,
      select,
      textarea {
        width: 90%;
        padding: 0.8em;
        margin: 20px 5px 0 5px;
      }
      p {
        margin: 20px 5px 20px 5px;
        text-align: center;
      }
    }
  }

  .mail-container {
    width: 25%;

    position: absolute;
    top: 60%;
    left: 40%;
    padding: 10px;
    opacity: 0;
    background-color: rgba(99, 99, 99, 0.6);
    border-radius: 5%;
    .infos {
      input {
        width: 90%;
        padding: 0.8em;
        margin: 20px 5px 0 5px;
      }
      button {
        width: 50%;
        padding: 0.8em;
        margin: 20px 5px;
      }
    }
  }

  .project-container {
    width: 35%;

    position: absolute;
    top: 60%;
    left: 55%;
    padding: 10px;
    opacity: 0;
    background-color: rgba(99, 99, 99, 0.6);
    border-radius: 5%;
    .infos {
      label {
        color: white;
      }

      select {
        width: 90%;
        padding: 0.8em;
        margin: 20px 5px 0 5px;
      }
      textarea {
        width: 90%;
        padding: 0.8em;
        margin: 20px 5px 0 5px;
      }
      button {
        width: 50%;
        padding: 0.8em;
        margin: 20px 5px;
      }
    }
  }

  .contactConfirm {
    position: absolute;
    top: 45%;
    left: 55%;
    opacity: 0;
    border: 1px white solid;

    padding: 15px;
    font-size: 40px;
    color: white;
    font-weight: bold;
  }
}

.btn {
  width: 90%;
  border: 1px solid #f0f0f0;
  color: #f0f0f0;
  background-color: black;
  margin: 15px 5px 5px 5px;
  padding: 1em 1em;
  text-align: center;
  transition: all 0.3s;
  cursor: pointer;

  &:hover {
    color: black;
    background-color: #f0f0f0;
    box-shadow: 0px 0px 20px white, 0px 0px 60px white, 0px 0px 100px white;
  }
}

#btn_form {
  opacity: 0;
}
</style>
