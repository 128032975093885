<template>
  <div class="entete">
    <nav class="navigation">
      <div class="lien" @click="retour">Retour / Back</div>
    </nav>
    <div class="titre">
      MON CV
      <div class="titre-en">Resume</div>
    </div>
  </div>
  <div class="container">
    <div class="download">
      <a
        href="https://atom-dev.net/download/CV-Vincent%20CHANUT-AtomDev.pdf"
        target="_blank"
      >
        Afficher la version téléchargeable</a
      >
    </div>
    <div class="cv">
      <div class="cv-titre">
        <div>Développement WEB - En parallèle de mon emploi</div>
        <div class="titre_date">Jan 2022 - Dec 2022</div>
      </div>
      <div class="cv-txt">
        Développement de plusieurs application WEB (gestion commerciale, Suivi
        de projet, site e-commerce, sites vitrine, logiciel de gestion gîtes et
        chambres d'hôtes)
      </div>
      <div class="cv-info">
        <p>Les application WEB sont développées en VueJs, Nodes (Full-Stack)</p>
        <p>Les sites WEB sont développées en VueJs</p>
        <p>
          Le logiciel de gestion (gîtes et chambre d'hôtes) est développé en C#
        </p>
        <p>Les bases de données utilisées sont: MariaDB, MongoDB et dynamoDB</p>
      </div>

      <div class="cv-titre">
        <div>Développeur application Windows + Full-Stack WEB</div>
        <div class="titre_date">Dec 2006 - Dec 2022</div>
      </div>
      <div class="cv-txt">
        Développment d'un EPR complet pour les entreprise industrielle (Devis,
        Cde client et fournisseur, Ordres de fabrication, Gestion des stocks,
        planning, GED...), ainsi que création et maintient du site WEB
      </div>
      <div class="cv-info">
        <p>Le logiciel est développé en vb.net et c#</p>
        <p>Base access et documents en cristal report</p>
        <p>Site internet en HTML CSS JS et PHP. Base MySql</p>
        <p>
          Dans le cadre de cet emploi, j'étais le seul développeur de la société
          qui comportait 2 membres (mon employeur s’occupait de la partie
          commerciale). J'ai donc dû gérer toute la partie fonctionnelle, et
          trouver seul les solutions aux problèmes rencontrés.
        </p>
        <p>
          En plus du développement, mes prestations consistées à:<br /><br />
          - Faire les installations (postes, serveur et un peu d'aministration
          réseau)<br />
          - Les formations<br />
          - Le conseil aux entreprises dans leur organisation<br />
          - Assurer la maintenance et le debuggage<br />
          - Démonstration avant vente
        </p>
      </div>

      <div class="cv-titre">
        <div>Développeur application Windows</div>
        <div class="titre_date">Sep 2004 - Dec 2006</div>
      </div>
      <div class="cv-txt">
        Développement d'une application pour la comptabilité hospitalière
      </div>
      <div class="cv-info">
        <p>Logiciel développé en vb 6 et base de donnée Informix</p>
        <p>L'entrprise comportait plus de 40 colaborateurs</p>
        <p>J'ai donc une bonne connaissance du travail en équipe</p>
      </div>

      <div class="cv-titre">
        <div>Installateur parc informatique</div>
        <div class="titre_date">Juin 2004 - Jui 2004</div>
      </div>
      <div class="cv-txt">
        Installation du parc informatique de l'hopital Emile Roux au Puy en
        Velay
      </div>
      <div class="cv-info">Installation des postes. Gestion du réseaux.</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "cv",
  methods: {
    retour() {
      this.$router.push("/Competence");
    },
  },
};
</script>

<style scoped lang="scss">
.container {
  min-height: 90vh;
  background-color: black;
  border-top: 1px lightgray solid;

  .download {
    width: 170px;
    height: 42px;
    border: 1px solid #f0f0f0;
    color: #f0f0f0;
    margin: 12px auto;
    text-align: center;
    transition: 0.2s ease;
    a {
      text-decoration: none;
      color: #f0f0f0;
      transition: 0.2s ease;
    }

    &:hover {
      color: #f0f0f0;
      background-color: #f0f0f0;
      a {
        color: #0f0f0f;
      }
    }
  }
}

.cv {
  width: 66%;
  margin: auto;
  padding: 10px 0 20px 0;
  text-align: left;
  transition: 0.3s;

  .cv-titre {
    padding: 55px 0 15px 0;
    margin: 0 5px 0px 0;
    font-size: 20px;
    text-align: left;
    font-weight: bold;
    color: white;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .titre_date {
      border-left: 1px dotted #5f5f5f;
      color: #9f9f9f;
    }
  }

  .cv-txt {
    font-size: 14px;
    text-align: left;
    color: white;
    padding: 0 0 10px 0;
  }

  .cv-info {
    font-size: 14px;
    color: rgb(130, 130, 130);
  }
}

@media screen and (max-width: 500px) {
  .cv {
    width: 90%;
  }

  .entete {
    .titre {
      font-size: 0.8em;
    }
    .retour {
      font-size: 0.8em;
    }
  }
}
</style>