<template>
  <div class="game" id="game">
    <div class="jump">Space for Jump: {{ nbJump }}</div>
    <div class="score">Score: {{ score }}</div>
    <div class="over" v-if="collision == true">
      GAME OVER :)
      <p>Votre score: {{ score }}</p>
      <div class="startBtn" @click="restart">
        Restart
      </div>
    </div>
    <div class="start" v-if="affStart">
      <p>
        Utilisez les fleches directionnelle pour aller à gauche ou a droite
      </p>
      <p>
        Espace permet de sauter
      </p>
      <p>
        Flèche haut accélére (ajoute 50 pts)
      </p>
      <div class="startBtn" @click="startGame">
        Start
      </div>
    </div>
  </div>
</template>

<script>
import * as THREE from "three";
import gsap from "gsap";
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';
import { OBJLoader } from 'three/examples/jsm/loaders/OBJLoader.js';

export default {
  name: "game3d",
  data() {
    return {
      xShip: 0,
      vitesse: 1,
      vitesseBouge: 2,
      score: 0,
      collision: false,
      zControle: -198,
      cpt: 0,
      jump: false,
      nbJump: 3,
      affStart: true,
      bonus: 0,
      boost: 0,
      addScore: 0,
    };
  },
  mounted() {
    this.init3D();
    

    window.addEventListener("keydown", this.bouge);
  },
  methods: {
    init3D() {
      this.INTERSECTED = null;
      this.pointer = new THREE.Vector2();

      this.scene = new THREE.Scene();
      this.scene.fog = new THREE.FogExp2(0x000000, 0.001);
      this.camera = new THREE.PerspectiveCamera(
        90,
        window.innerWidth / window.innerHeight,
        0.1,
        1000
      );
      // this.camera.lookAt(-50, 0, 0);
      this.camera.position.z = 0;
      this.camera.position.y = 25;
      this.raycaster = new THREE.Raycaster();

      this.camera.lookAt(0, 0, this.camera.position.z - 100);

      this.renderer = new THREE.WebGLRenderer({ antialias: true });
      this.renderer.setSize(window.innerWidth, window.innerHeight);
      this.renderer.shadowMap.enabled = true;
      this.renderer.shadowMap.type = THREE.PCFSoftShadowMap;

      let game = document.getElementById("game");
      game.appendChild(this.renderer.domElement);

      // instantiate a loader
      this.loader3D = new OBJLoader();

      // load a resource
       let oo = new THREE.Object3D();

      this.loader3D.load(
        // resource URL
        '3D/justigue league flying vehicle.obj',
        // called when resource is loaded
        function ( ship2 ) {
          oo = ship2
        },
        // called when loading is in progresses
        function ( xhr ) {

          console.log( ( xhr.loaded / xhr.total * 100 ) + '% loaded' );

        },
        // called when loading has errors
        function ( error ) {

          console.log( 'An error happened:', error );

        }
      );

      this.vaisseau = oo

      let geometry = new THREE.PlaneBufferGeometry(300, 10000, 100, 100);

      const loader = new THREE.TextureLoader();
      const texture = loader.load("textures/line2.jpg");

      let material = new THREE.MeshStandardMaterial({
        color: "CCFCF5",
        map: texture,
      });
      //let material = new THREE.MeshStandardMaterial({color: 'ff0000'})

      this.plane = new THREE.Mesh(geometry, material);
      this.plane.rotation.x = 4.705;
      this.plane.position.y = -5;
      this.plane.receiveShadow = true;
      this.scene.add(this.plane);

      this.tabObs = [];
      for (let i = 1; i <= 1000; i++) {
        //console.log(i)
        this.createObstacle(-i * 200);
      }

      this.pointlight = new THREE.PointLight(0xffffff, 2, 1000);
      this.pointlight.position.y = 100;
      this.pointlight.position.z = this.camera.position.z - 25;
      this.pointlight.castShadow = true
      this.scene.add(this.pointlight);

      this.shipGrp = new THREE.Group();

      /*var shipGeometry = new THREE.BoxGeometry(2,2,4)
      var shipMaterial = new THREE.MeshStandardMaterial({ color: 0xffffff , transparent:true, opacity: 0.9, roughness: 0.9})
      var ship = new THREE.Mesh(shipGeometry,shipMaterial)
      ship.position.x=-1
      ship.position.y=0
      ship.position.z = -30
      this.shipGrp.add(ship)

      var shipGeometry = new THREE.BoxGeometry(6,3,5)
      var ship = new THREE.Mesh(shipGeometry,shipMaterial)
      ship.position.x=-3
      ship.position.y=0
      ship.position.z = -26
      this.shipGrp.add(ship)

      this.scene.add(this.shipGrp)*/

      var shipGeometry = new THREE.SphereGeometry(3, 20, 20);
      var shipMaterial = new THREE.MeshStandardMaterial({
        color: 0xF28407,
        transparent: true,
        opacity: 0.9,
        roughness: 0.9,
        wireframe: true,
      });
      this.ship = new THREE.Mesh(shipGeometry, shipMaterial);
      this.ship.position.x = -1;
      this.ship.position.y = 0;
      this.ship.position.z = -30;
      this.ship.castShadow = true; //default is false
      this.ship.receiveShadow = false; //default
      this.scene.add(this.ship);

      this.clock = new THREE.Clock();

      this.windowHalfX = window.innerWidth / 2;
      this.windowHalfY = window.innerHeight / 2;
    },
    startGame() {
      this.affStart=false
      
      this.animate();
      this.vaisseau.position.x = -1;
      this.vaisseau.position.y = 5;
      this.vaisseau.position.z = -30;
      this.vaisseau.scale.x = 0.1
      this.vaisseau.scale.y = 0.1
      this.vaisseau.scale.z = 0.1
      this.vaisseau.castShadow = true; //default is false
      this.vaisseau.receiveShadow = false; //default

console.log('vaisseau: ' , this.vaisseau)

      this.scene.add(this.vaisseau)
    },
    ajouteDansScene(obj) {
      this.scene.add(obj)
    },
    animate() {
      this.idAnim = requestAnimationFrame(this.animate);

      let time = this.clock.getElapsedTime().toFixed(0);
      this.vitesse = this.boost + time / 10 + 1;
      this.vitesseBouge = this.boost + time / 20 + 1;

      this.score = (time * 10 + this.vitesse + this.addScore).toFixed(0);
      //this.score = this.score.toFixed(0)

      //console.log(this.vitesse)

      let posZ = this.camera.position.z - this.vitesse;
      posZ = posZ.toFixed(0);
      this.camera.position.z = posZ;
      this.pointlight.position.z = this.camera.position.z;
      this.plane.position.z = this.camera.position.z;

      this.ship.position.z = posZ - 30;
      this.ship.position.x = this.xShip;
      this.ship.rotation.x -= this.vitesse / 10;

      this.bonus += this.vitesse / 10
      if (this.bonus > 500) {
        this.nbJump ++
        this.bonus = 0
      }


      //console.log(this.bonus)
      //tous les 5 unités de camera en avant, on cree un obstable
      /*if (posZ % 50 === 0) {
        console.log("ajout");
        this.createObstacle(posZ)
      }*/

      if (this.ship.position.z < this.zControle) {
        // console.log(this.ship.position.z + '    ' + this.zControle)
        // alert(this.zControle)

        this.zControle -= 200;
        this.verifCollison(this.cpt, this.ship.position.x);
        this.cpt++;
      }

      this.renderer.render(this.scene, this.camera);
    },
    createObstacle(posZ) {
      let obstacleZ = posZ; // - 100;
      let d1 = Math.random() * (window.innerWidth / 25);
      let d2 = Math.random() * 20;
      let d3 = Math.random() * 10;
      if (d1 < 3) d1 = 3
      if (d2 < 3) d2 = 3
      const obsGeometry = new THREE.BoxGeometry(d1, d2, d3);
      const loader = new THREE.TextureLoader();
      const texture = loader.load("textures/line1.jpg");
      

      let c1 = (Math.random() * 255).toFixed(0);
      let c2 = (Math.random() * 255).toFixed(0);
      let c3 = (Math.random() * 255).toFixed(0);

      const color3 = new THREE.Color("rgb(" + c1 + ", " + c2 + ", " + c3 + ")");

      const obsMaterial = new THREE.MeshStandardMaterial({
        color:color3,
        transparent: true,
        opacity: 0.95,
        roughness: 0.1,
        metalness: 0.8,
        map: texture,
      });
      //const obsMaterial = new THREE.MeshBasicMaterial({ color: 0x0000ff });
      const obs = new THREE.Mesh(obsGeometry, obsMaterial);
      obs.castShadow = false; //default is false
      obs.receiveShadow = true; //default

      let obstacleX = Math.random() * 200 - 100;
      obs.position.x = obstacleX;
      obs.position.z = obstacleZ;
      //console.log(obs.position.z)
      obs.position.y = 0;

      this.scene.add(obs);
      this.tabObs.push(obs);
      //console.log(this.tabObs[0].geometry.parameters.width)
      //console.log(obs)
    },
    bouge(e) {
      console.log(e.code);
      //console.log(e.code)
      switch (e.code) {
        case "ArrowRight":
          this.xShip += this.vitesseBouge;
          break;
        case "ArrowLeft":
          this.xShip -= this.vitesseBouge;
          break;
        case "Space":
          if (this.nbJump > 0) {
            this.jump = true;
            this.nbJump--;
            const tl = gsap.timeline({ paused: true });
            tl.to(this.ship.position, { y: 20, duration: 0.5 });
            tl.to(
              this.ship.position,
              {
                y: 0,
                duration: 0.7,
                onComplete: () => {
                  this.jump = false;
                },
              },
              "+=0.1"
            );
            tl.to(this.ship.position, { y: 2.5, duration: 0.1 });
            tl.to(
              this.ship.position,
              {
                y: 0,
                duration: 0.2,
                onComplete: () => {
                  this.jump = false;
                },
              },
              "+=0.1"
            );
            tl.play();
          }
          break;
          case "ArrowUp":
            this.addScore += 50
            this.boost += 1.5

            //console.log(this.vitesse);
            break;

      }

      const ratio = window.innerWidth / 30;
      if (this.xShip > ratio) {
        this.xShip = ratio;
      }
      if (this.xShip < -ratio) {
        this.xShip = -ratio;
      }
    },
    verifCollison(j, x1) {
      if (this.jump == true) {
        return;
      }
      const xObs = this.tabObs[j].position.x;
      const larg = this.tabObs[j].geometry.parameters.width;
      const xMin = xObs - larg / 2;
      const xMax = xObs + larg / 2;

      //console.log(xObs + '  larg: ' + larg + '   min: ' + xMin + '   max: ' + xMax + '  x1 ' + x1)

      if (x1 >= xMin - 2 && x1 <= xMax + 2) {
        console.log("collision");
        this.collision = true;
        cancelAnimationFrame(this.idAnim);
      }
    },
    restart() {
      window.location.reload()
    }
  },
};
</script>

<style scoped lang="scss">
.game {
  width: 100%;
  height: 100vh;
  background-color: dimgray;

  .jump {
    color: white;
    position: absolute;
    padding: 15px;
    font-size: 20px;
    left: 20px;
    top: 15px;
  }
  .score {
    color: white;
    position: absolute;
    padding: 15px;
    font-size: 20px;
    right: 20px;
    top: 15px;
  }

  .over {
    color: white;
    background-color: rgba(0, 0, 0, 0.5);
    position: absolute;
    padding: 30px;
    font-size: 30px;
    left: 50%;
    top: 40%;
    transform: translateX(-50%);
    border: 2px white solid;
  }
}

.start{
  position: absolute;
  top:50%;
  left: 50%;
  transform: translate(-50%,-50%);
  padding: 20px;
  font-size: 25px;
  text-align: center;
  color:white;
  border: 1px white solid;

  
}

.startBtn{
    padding: 5px;
    border: 1px white solid;
    cursor: pointer;
    transition: 0.5s ease-in-out;
    text-align: center;
    &:hover{
      background-color: white;
      color:black
    }
  }
</style>
