<template>
  <div class="container" id="container"
     @mousemove="onPointerMove"
     @mousedown="detruit"
     
  >
      <div class="destruction" v-show="modeDestruction">
          Cliquer sur une tour pour la détruire => {{ nbDetruit }} / 2500
      </div>

        <div class="retour" @click="retour">
            Retour / Back
        </div>

      <div class="cadre" v-show="modeDestruction==false">
        <div class="titre">
          <span class="txt">
              WELCOME
          </span>
           <span class="txt">
              TO
          </span>
           <span class="txt">
              FUTURE-CITY
          </span>
        </div>

        <div class="btn" @click="vuGrande">
            Interactive City
        </div>
      </div>
      
  </div>
</template>

<script>

import * as THREE from 'three'
import gsap from 'gsap'

export default {
    name: 'futureCity',
    data() {
        return {
            verifIntersec: false,
            lastScroll: 0,
            modeDestruction: false,
            nbDetruit: 0,
        }
    },
    mounted() {
        this.init3D()
        this.animate()

        const tl = new gsap.timeline({paused: true, delay: 0.8})
        tl.to('.titre span', {top:'40px', opacity: 1, duration:0.8, ease: 'power2.out', stagger: 0.2})
        tl.to('.btn', {scale: 1,opacity: 1, duration:0.8, ease: 'power2.out'} , '-=0.5')
        tl.to('.retour', {scale: 1,opacity: 1, duration:0.5, ease: 'power2.out'} , '-=0.5')
        tl.play()

        window.addEventListener('resize', this.onWindowResize)
        window.addEventListener('popstate', this.stopAnimation)

    },
    methods: {
        init3D() {

            this.INTERSECTED = null
            this.pointer = new THREE.Vector2();

            this.scene = new THREE.Scene();
           // this.scene.fog = new THREE.FogExp2(0x000000, 0.0002);
            this.camera = new THREE.PerspectiveCamera(
                90,
                window.innerWidth / window.innerHeight,
                0.1,
                1000
            );
            this.camera.lookAt(-50, 0, 0);

            this.raycaster = new THREE.Raycaster();

            this.renderer = new THREE.WebGLRenderer({ antialias: true });
            this.renderer.setSize(window.innerWidth, window.innerHeight);
            let container = document.getElementById("container");
            container.appendChild(this.renderer.domElement);

            this.windowHalfX = window.innerWidth / 2;
            this.windowHalfY = window.innerHeight / 2;

            this.camera.position.z = 250;
            this.camera.position.x = 0;
            this.camera.position.y = 0;

            this.city = new THREE.Group()

            var geometryPlan = new THREE.BoxGeometry(105,5,105)
            var materialPlan = new THREE.MeshStandardMaterial( {color: 0x3f3f3f, side: THREE.DoubleSide, roughness: 0.5, metalness: 1})
            this.plan = new THREE.Mesh(geometryPlan,materialPlan)
            //this.plan.rotation.x=1.3
            this.city.add(this.plan)
            //const axesHelper = new THREE.AxesHelper( 100 );
            //this.scene.add( axesHelper );

            this.tabCube= []

            let coul = [0x0000ff,0xff0000,0x00ff00,0xffffff,0xff00ff,0x00ffff,0x0f0f0f,0xffff00]
            let indC = -1

            for(let i=-50; i<=50;i+=4) {

                for(let j=-50; j<=50; j+=4) {

                    let cx1 = Math.round( Math.random() * 3 )
                    if (cx1==0) { cx1 = 1 }

                    let cz1 = Math.round( Math.random() * 3 )
                    if (cz1==0) { cz1 = 1 }

                    let cy1 = Math.round( Math.random() * 50 )
                    if (cy1<6) { cy1 = 6 }

                    indC++
                    if (indC>8) indC=0
                    let C = coul[indC]

                    var geometryCube = new THREE.BoxGeometry(cx1,cy1,cz1,4,4,4)
                    var materialCube = new THREE.MeshStandardMaterial( {color: C, transparent: true, opacity: 0.9, roughness: 0.8, metalness: 0.3, refractionRatio: 1})
                    const cube = new THREE.Mesh(geometryCube,materialCube)
                    cube.castShadow = true
                    cube.receiveShadow = true
                    cube.position.x= i
                    cube.position.z= j
                    cube.position.y = (cy1 / 2) + 1
                    this.tabCube.push(cube)
                    //this.city.add(cube)

                }

                
            }

            //console.log(this.tabCube[0])
            for ( let t=0; t<=this.tabCube.length;t++ ) {
                this.city.add(this.tabCube[t])
            }

            //console.log(tabCube)
            /*
            var light = new THREE.PointLight( 0xff00ff, 75, 2000 );
            light.position.set( -30, 80, -50);
            this.scene.add( light );

            light.position.set( 30, -80, 50);
            this.scene.add( light );

            light.position.set( -80, 80, -80);
            this.scene.add( light );

            light.position.set( 80, 80, 80);
            this.scene.add( light );

             light.position.set( 0, 80, 0);
            this.scene.add( light );*/

            //Create a DirectionalLight and turn on shadows for the light
            const light = new THREE.DirectionalLight( 0xffffff, 5, 170 );
            light.position.set( 0, 200, 50 ); //default; light shining from top
            //light.castShadow = true; // default false
            this.scene.add( light );

            this.city.position.y = -(window.innerHeight / 4) + 100
            this.city.position.x = window.innerWidth / 8 + 100

            this.scene.add(this.city)

        },
        animate() {
            this.idAnim = requestAnimationFrame(this.animate)
            this.city.rotation.y += 0.005
            this.camera.lookAt( this.scene.position );
            //this.camera.lookAt( -90,50,0 );

            // find intersections
            if (this.verifIntersec==true) {
				this.raycaster.setFromCamera( this.pointer, this.camera );

				const intersects = this.raycaster.intersectObjects( this.tabCube );

				if ( intersects.length > 0 ) {

					if ( this.INTERSECTED != intersects[ 0 ].object ) {

						if ( this.INTERSECTED ) this.INTERSECTED.material.emissive.setHex( this.INTERSECTED.currentHex );

						this.INTERSECTED = intersects[ 0 ].object;
                        
						this.INTERSECTED.currentHex = this.INTERSECTED.material.emissive.getHex();
						this.INTERSECTED.material.emissive.setHex( 0xff0000 );

					}

				} else {

					if ( this.INTERSECTED ) this.INTERSECTED.material.emissive.setHex( this.INTERSECTED.currentHex );

					this.INTERSECTED = null;

				}
            }

            this.renderer.render( this.scene, this.camera )
        },
        onPointerMove( event ) {

			this.pointer.x = ( event.clientX / window.innerWidth ) * 2 - 1;
			this.pointer.y = - ( event.clientY / window.innerHeight ) * 2 + 1;

		},
        vuGrande() {

            const tl = new gsap.timeline({paused: true})
            tl.to(this.city.position, {y:-50, duration:2, ease: 'power2.out'})
            tl.to(this.city.position, {x:0, duration:2, ease: 'power2.out'}, '-=2')
            tl.to(this.camera.position, {z:50, duration:2, ease: 'power2.out'}, '-=2')
            tl.to(this.camera.position, {y:30, duration:2, ease: 'power2.out', onComplete: () => {
                tl.to('.titre span', {opacity: 0, duration:0.3, ease: 'power2.out', stagger: 0.2})
                tl.to('.btn', {opacity: 0, duration:0.2, ease: 'power2.out'})
                this.modeDestruction=true
                tl.to('.destruction', {opacity: 1, duration:0.5, ease: 'power2.out'})
                //const cadre = document.getElementsByClassName('cadre')
               // cadre.style.top = '20px'
            }}, '-=2')
            this.verifIntersec=true
            tl.play()
            /*this.city.position.y = -50
            this.city.position.x = 0
            this.camera.position.z = 50
            this.camera.position.y=30*/
            this.camera.lookAt( this.scene.position );
        },
        detruit() {
            if (this.verifIntersec==true) {
                console.log('dddd')
				this.raycaster.setFromCamera( this.pointer, this.camera );
                
				const intersects = this.raycaster.intersectObjects( this.tabCube );
                //console.log('i: ' + intersects[0].object.geometry)
				if ( intersects.length > 0 ) {
                    const tl = new gsap.timeline({paused: true})
                    tl.to(intersects[0].object.scale, {y:0.001, duration: 1, ease: 'power2.in'})
                    tl.to(intersects[0].object.position, {y:0, duration: 1, ease: 'power2.in'} , '-=1')
                    tl.play()
                    this.nbDetruit++
					/*if ( this.INTERSECTED != intersects[ 0 ].object ) {

						this.INTERSECTED = intersects[ 0 ].object;
                        this.INTERSECTED.currentHex = this.INTERSECTED.material.emissive.getHex();
						this.INTERSECTED.material.emissive.setHex( 0x000000 );

                    } else {

                        if ( this.INTERSECTED ) this.INTERSECTED.material.emissive.setHex( this.INTERSECTED.currentHex );

                        this.INTERSECTED = null;

                    }*/
                }               
                this.renderer.render( this.scene, this.camera )
            }
        },
        zoomInOut (){

                       console.log('sio')
            if (this.verifIntersec==true) {
                var st = document.documentElement.scrollTop;

                console.log('st:' + st + ' ls: ' + this.lastScroll)

                if (st > this.lastScroll) {
                    //zoom in
                    this.lastScroll = st
                    let zoom = this.camera.zoom
                    zoom+=0.1
                    const tl = new gsap.timeline({paused: true})
                    tl.to(this.camera, {zoom:zoom, duration:0.5, ease: 'power2.out'})
                    tl.play()
                }

                
            }   
        },
        onWindowResize(e) {
            this.windowHalfX = window.innerWidth / 2;
            this.windowHalfY = window.innerHeight / 2;

            this.camera.aspect = window.innerWidth / window.innerHeight;
            this.camera.updateProjectionMatrix();

            this.renderer.setSize(window.innerWidth, window.innerHeight);
        },
        retour() {
            this.stopAnimation()
            this.$router.push('/threeExample')
        },
        stopAnimation() {
            console.log('cassé')
            cancelAnimationFrame(this.idAnim)
        },  
    }
}
</script>

<style scoped lang="scss">

    .container{
        width: 100%;
        height: 100vh;
        background-color: rgb(17, 17, 17);

        .cadre{
            position: absolute;
            top:32%;
            left: 10%;

            .titre{
                position: relative;
                

                span {
                    position: relative;
                    top: 10px;
                    font-size: 55px;
                    font-weight: bold;
                    color:white;
                    opacity: 0;
                }
            }

            .btn{
                position: relative;
                top: 70px;
                color: white;
                padding: 0.5em 1em;
                font-size: 25px;
                border: white 2px solid;
                width: 200px;
                text-align: center;
                transition: 0.5s ease-in-out;
                opacity: 0;
                scale: 0.7;
                cursor: pointer;

                &:hover{
                    background-color:white;
                    color: black;
                    
                }
            }
        }

        .destruction{
            position: absolute;
            top: 5%;
            left: 5%;
            color: white;
            font-size: 25px;
            opacity: 0;
        }

        .retour{
            position: absolute;
            top: 5%;
            right: 5%;
            color: white;
            font-size: 25px;
            border: 1px white solid;
            padding: 0.5em 1em;
            opacity: 0;
            transition: 0.3s ease-in;
            cursor: pointer;

            &:hover{
                    background-color:white;
                    color: black;
                    
                }
        }
    }

    

</style>