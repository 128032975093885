<template>
  <div class="container">
    <div class="navigation">
      <div class="lien" @click="retour">Retour / Back</div>
    </div>

    <div class="liste">
      <div class="card">
        <div class="image">
          <img src="textures/other/gite1.jpg" alt="" />
        </div>
        <div class="titreProjet">Gestion des Gites (C#)</div>
        <div class="typeProjet">Projet perso.</div>
        <div class="detail">
          Logiciel de gestion de Gîtes et camping, permettant:<br />
          - Gestion des gîtes et emplacements<br />
          - Gestion des clients<br />
          - Gestion des réservations<br />
          - Facturations<br />
          - Planning et taux d'occupation.
        </div>
      </div>
      <div class="card">
        <div class="image">
          <img src="textures/other/maintenance.jpg" alt="" />
        </div>
        <div class="titreProjet">Gestion de maintenances (C#)</div>
        <div class="typeProjet">Projet perso.</div>
        <div class="detail">
          Logiciel de gestion des maintenances:<br />
          - Gestion des clients<br />
          - Création et suivi de maintenances<br />
          - Totalisation des heures et des coûts par mois<br />
          - Préparation de la facturation.
        </div>
      </div>

      <div class="card">
        <div class="image"></div>
        <div class="titreProjet">Création d'un EPR (C# et VB.net)</div>
        <div class="typeProjet">Précédent emploi</div>
        <div class="detail">
          ERP pour les métiers de la tolerie et de la mécanique:<br />
          - Gestion des clients, matières, postes, composants et fournisseur<br />
          - Gestion des devis<br />
          - Gestion des commandes client<br />
          - Gestion des BL<br />
          - Gestion des Ordres de fabrication et pointages ateliers<br />
          - Gestion des commandes d'achats<br />
          - Gestion de la facturation<br />
          - Gestion électronique des documents<br />
          - Tableaux de bords
        </div>
      </div>

      <div class="card">
        <div class="image"></div>
        <div class="titreProjet">Logiciel pour le batiment (C# et VB.net)</div>
        <div class="typeProjet">Précédent emploi</div>
        <div class="detail">
          Logiciel de gestion pour les metiers du batiment et de l'artisanat:<br />
          - Gestion des clients, références et fournisseurs<br />
          - Gestion des devis<br />
          - Gestion des commandes d'achat<br />
          - Gestion des factures<br />
          - Gestion de travaux personnalisés
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "other",
  methods: {
    retour() {
      this.$store.state.modeProjet = "rapide";
      this.$router.push("/project3d");
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  position: relative;
  width: 100%;
  min-height: 100vh;

  .liste {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    margin: 100px auto;
    width: 100%;
    max-width: 1440px;
    transition: 0.5s ease-in-out;
    .card {
      width: 90%;
      height: 200px;
      border: 1px solid #f0f0f0;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      gap: 15px;
      margin: 0 0 15px 0;
      .image {
        flex-basis: 20%;
        img {
          height: 165px;
        }
      }
      .titreProjet {
        flex-basis: 20%;
        font-size: 2em;
        color: #f0f0f0;
      }
      .typeProjet {
        flex-basis: 20%;
        font-size: 1.2em;
        color: #f0f0f0;
      }
      .detail {
        flex-basis: 40%;
        color: #f0f0f0;
        font-size: 0.8em;
      }
    }
  }
}

@media screen and (max-width: 700px) {
  .container {
    .liste {
      max-width: 100wv;
      margin: 100px 0;
      .card {
        gap: 2px;
        min-height: 300px;
        .image {
          img {
            width: 80px;
            height: 60px;
          }
        }
        .titreProjet {
          font-size: 0.9em;
        }
        .typeProjet {
          font-size: 0.7em;
        }
        .detail {
          font-size: 0.5em;
        }
      }
    }
  }
}
</style>