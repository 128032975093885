<template>
  <div class="container" id="container">

  </div>
</template>

<script>

import * as THREE from 'three'
import gsap from 'gsap'

export default {
    name:'diaporama',
    data() {
        return {
            yPos:0,
            inter1: null,
        }
    },
    mounted() {
        this.init3D()
        this.animate()
        window.addEventListener('wheel', this.onWheel)
        //window.addEventListener('mousemove', this.onPointerMove)

    },
    methods: {
        init3D() {

            this.pointer = new THREE.Vector2();

            this.raycaster = new THREE.Raycaster();

            this.scene = new THREE.Scene();
            this.scene.fog = new THREE.FogExp2(0xffffff, 0.0003);
            this.camera = new THREE.PerspectiveCamera(
            75,
            window.innerWidth / window.innerHeight,
            0.1,
            1000
            );
            this.camera.lookAt(0, 0, 0);
            this.renderer = new THREE.WebGLRenderer({antialias:true});
            this.renderer.setSize(window.innerWidth, window.innerHeight);
            let container = document.getElementById("container");
            container.appendChild(this.renderer.domElement);
            this.windowHalfX = window.innerWidth / 2;
            this.windowHalfY = window.innerHeight / 2;

            /*
            var geometry = new THREE.TorusGeometry( 1, 0.5, 16, 100 );
            var material = new THREE.MeshBasicMaterial( { color: 0x000fff } );
            this.cube = new THREE.Mesh( geometry, material );
            this.scene.add( this.cube );*/

            this.camera.position.z = 100;
            this.camera.position.x = 0;
            this.camera.position.y = -200;

            this.tabPhoto = []

            for (let i = 1; i <= 10; i++) {
                var texture = new THREE.TextureLoader().load( "textures/diaporama/" + i + ".jpg" );
                texture.wrapS = THREE.RepeatWrapping;
                texture.wrapT = THREE.RepeatWrapping;
                texture.repeat.set( 1, 1 );
                var material1 = new THREE.MeshBasicMaterial( {color: 0xffffff, side: THREE.DoubleSide, map: texture, transparent: true})
                //var material1 = new THREE.MeshBasicMaterial( {color: 0xffffff, side: THREE.DoubleSide})

                var geometry = new THREE.PlaneGeometry(100, 60 ,10,10);
                const photo = new THREE.Mesh(geometry,material1)
                photo.position.x = 50 * Math.random()
                photo.position.y = -((i-1) * 100) + 10
                photo.position.z = 30 * Math.random()
                photo.rotation.y = 2.35
                this.tabPhoto.push(photo)
               // console.log(photo)
            }

            for (let i = 0; i < 10; i++) {

                this.scene.add(this.tabPhoto[i])

            }

            this.renderer.render( this.scene, this.camera )
        },

        onWheel(e) {
            this.yPos += e.deltaY
            console.log(this.yPos)

            this.camera.position.y = -this.yPos*0.1;

            for (let ph of this.tabPhoto) {

                if (this.camera.position.y < (ph.position.y+50) ) {
                    console.log(this.camera.position.y + '   -   ' + ph.position.y)
                    const tl = new gsap.timeline({paused: true})
                    tl.to(ph.scale, {x:1.2, y:1.2, duration: 0.5, ease: 'power2.in'})
                    tl.to(ph.rotation, {y:0, duration: 0.5, ease: 'power2.in'} , '-=0.5')
                    tl.to(ph.position, {x:-50, duration: 0.5, ease: 'power2.in'} , '-=0.5')
                    tl.play()
                }

            }


            this.renderer.render( this.scene, this.camera )
        },
        onPointerMove() {
            this.raycaster.setFromCamera( this.pointer, this.camera );

				const intersects = this.raycaster.intersectObjects( this.tabPhoto );
                
                //console.log('i: ' + intersects[0].object.geometry)
				if ( intersects.length > 0 ) {
                    console.log('trouve')
                    if (this.inter1 != intersects) {
                        console.log('trouve1')
                        this.inter1 = intersects
                        const tl = new gsap.timeline({paused: true})
                        tl.to(intersects[0].object.scale, {x:1.2, y:1.2, duration: 1, ease: 'power2.in'})
                        tl.to(intersects[0].object.rotation, {y:0, duration: 1, ease: 'power2.in'} , '-=1')
                        tl.play()
                        //on remet les phots non pointées a leur position d'origine
                        for (const obj of this.tabPhoto) {

                        }

                        this.renderer.render( this.scene, this.camera )
                    }
                    //console.log(intersects)
                    
					
                }   
                
                
        },
        animate() {
            requestAnimationFrame(this.animate)

            this.renderer.render( this.scene, this.camera )

        }

    },
}
</script>

<style>
    .container {
        width: 100%;
        height: 100vh;
        background-color: black;
    }
</style>