<template>
  <div class="entete">
    <nav class="navigation">
      <div class="lien" @click="retour">Retour / Back</div>
    </nav>

    <div class="titre">
      A propos
      <div class="titre-en">About</div>
    </div>
  </div>
  <h2>Hey, je m'appelle Vincent CHANUT</h2>
  <h2>Je suis développeur FreeLance</h2>

  <div class="detail">
    <p>
      Développeur d'application windows (C# et Vb.net) et d'appli WEB Depuis
      plus de 18 ans
    </p>
    <p>Et développeur WEB FullStack auto-didacte</p>
  </div>
  <div class="detail">
    <p>
      J'ai travaillé pendant 15 ans pour une entreprise développant un ERP Pour
      l'industrie (principalement tôlerie et mécanique industrielle)
    </p>
    <p>J'étais seul développeur autant pour le logiciel que pour le site WEB</p>
    <p>
      J'ai donc très vite appris à être autonome et à trouver seul les solutions
      aux problèmes posés par les clients
    </p>
    <p>
      Pour autant, j'ai souvent travailler en partenariat avec d'autres
      entreprises de développement à l'élaboration de passerelles ...
    </p>
    <p>Je connais le travail en équipe (précédent emploi ...)</p>
  </div>
  <div class="detail">
    <p>N'hésitez pas à me contacter si vous avez des projets intéressants</p>
    <p>Je serai ravi de mettre toute mon expérience à votre service</p>
  </div>
  <div class="detail">
    <p>Au tel: 06.22.14.29.20</p>
    <p>Par mail: contact@atom-dev.net</p>
  </div>
  <div class="reseaux">
    <!-- <div class="icone">
      <img src="/icons/facebook.png" alt="">
    </div> -->
    <div class="icone">
      <a
        href="https://www.linkedin.com/in/vincent-chanut-35615b90/"
        target="_blank"
        ><img src="/icons/linkedin.png" alt="mon LinkedIn"
      /></a>
    </div>
  </div>
</template>

<script>
import gsap from "gsap";

export default {
  name: "About",
  mounted() {
    const tl = new gsap.timeline();
    tl.to("p", {
      opacity: 1,
      margin: "25 0 0 0",
      duration: 0.3,
      stagger: 0.2,
      delay: 1,
    });
    tl.to(".icone", { opacity: 0.85, duration: 0.3, stagger: 0.5 });
  },
  methods: {
    retour() {
      this.$router.push("/Acceuil");
    },
  },
};
</script>

<style scoped lang='scss'>
h2,
h3 {
  margin: 35px 0;
  text-align: center;
  color: #fff;
  transition: 0.2s;
}

.detail {
  width: 90%;
  max-width: 600px;
  margin: 40px auto;
  color: #f0f0f0;
  text-align: center;
}

p {
  opacity: 0;
  margin-top: 0;
  transition: 0.2s;
}

.reseaux {
  width: 300px;
  margin: 30px auto;
  display: flex;
  justify-content: center;
  align-items: center;
  .icone {
    cursor: pointer;
    transition: 0.2s;
    opacity: 0;
    img {
      width: 60px;
      height: 60px;
    }

    &:hover {
      opacity: 1;
    }
  }
}

@media screen and (max-width: 500px) {
  .entete {
    .titre {
      font-size: 0.8em;
    }
    .retour {
      font-size: 0.8em;
    }
  }
  h2 {
    font-size: 0.8em;
  }
  p {
    font-size: 0.6em;
  }
}
</style>