<template>
  <div class="ecran" id="ecran">
    <nav class="navigation">
      <div class="lien" @click="retour">Retour / Back</div>
    </nav>
    <div class="entete">
      <div class="titre">
        MES COMPETENCES
        <div class="titre-en">My Skills</div>
      </div>
    </div>
    <div class="container1" id="container">
      <div class="cv">
        <div class="detail-txt">
          <p @click="AffCV"><u>Voir mon CV</u></p>
        </div>
      </div>

      <div class="detail">
        <div class="front">
          <div class="detail-titre">FRONT-END</div>
          <div class="detail-txt">
            <p>HTML5 css3 JavaScript</p>
            <p>scss</p>
            <p>Vue js 3 / 3.2</p>
            <p>Nuxt</p>
            <p>React</p>
            <p>NextJs</p>
            <p>Three js</p>
            <p>WebGL</p>
            <p>GSAP</p>
            <p>Axios / Fetch</p>
          </div>
        </div>
        <div class="back">
          <div class="detail-titre">BACK-END</div>
          <div class="detail-txt">
            <p>Node js</p>
            <p>Express js</p>
            <p>ejs</p>
            <p>Json Web Token (JWT)</p>
            <p>Mongoose - ps (postGrSQL) - Mysql - DynamoDB</p>
            <p>Cors</p>
            <p>Axios</p>
          </div>
        </div>
        <div class="ops">
          <div class="detail-titre">DEV APP</div>
          <div class="detail-txt">
            <p>C#</p>
            <p>VB.net</p>
            <p>VB 6</p>
            <p>Crystal Report</p>
            <p>Visual Studio / Visual Stutio Code</p>
          </div>
        </div>
      </div>
      <div class="divers">
        <div class="detail-titre">METHODOLOGIE</div>
        <div class="detail-txt">
          <p>Typescript</p>
          <p>test unitaire et tdd (jest)</p>
          <p>Clear Code / hériarchisation du code source</p>
          <p>REST</p>
        </div>
      </div>
      <div class="divers">
        <div class="detail-titre">DIVERS / Other</div>
        <div class="detail-txt">
          <p>DataBase: PostGrSQL - Mysql - MongoDB - DynamoDB - Access</p>
          <p>Connaissance en réseaux / Network - AWS</p>
          <p>Installation et formation</p>
          <p>Conseil en Entreprise: ERP/GPAO et veille technologique</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import gsap from "gsap";
import * as THREE from "three";
import router from "../router";
import VueCookies from "vue-cookies";
import * as FCT from "../js/functions";

export default {
  name: "Competence",
  data() {
    return {
      windowHalfX: 0,
      windowHalfY: 0,
      color: 0xc42020,
    };
  },
  beforeCreate: function () {
    document.body.className = "home";
  },
  mounted() {
    const C1 = VueCookies.get("atomColor");

    this.color = FCT.trouveColor(C1);

    window.addEventListener("popstate", this.stopAnimation);
    window.addEventListener("resize", this.onWindowResize);

    this.init3D();
    this.animate();
    const tl = new gsap.timeline({ paused: true, delay: 1 });
    tl.to(".detail", { opacity: 1, duration: 0.2, ease: "power2.out" });
    tl.to(
      ".divers",
      { opacity: 1, duration: 0.2, ease: "power2.out" },
      "-=0.5"
    );
    tl.to(
      ".cv",
      {
        opacity: 1,
        duration: 0.2,
        ease: "power2.out",
        onComplete: () => {
          const container = document.getElementById("container");
          container.style.scale = 1;
        },
      },
      "-=0.8"
    );
    //tl.to(".container", { css:{scaleX: 1, scaleY: 1}, duration: 0.5, ease: "power2.out" }, "-=0.3");
    tl.play();
  },
  methods: {
    AffCV() {
      this.stopAnimation();
      this.$router.push("/cv");
    },
    onWindowResize(e) {
      this.windowHalfX = window.innerWidth / 2;
      this.windowHalfY = window.innerHeight / 2;

      this.camera.aspect = window.innerWidth / window.innerHeight;
      this.camera.updateProjectionMatrix();

      this.renderer.setSize(window.innerWidth, window.innerHeight);
    },
    retour() {
      const tl = new gsap.timeline();
      tl.to(".detail", { opacity: 0, duration: 0.8, ease: "power2.out" });
      tl.to(
        ".divers",
        { opacity: 0, duration: 0.8, ease: "power2.out" },
        "-=0.5"
      );
      tl.to(".cv", { opacity: 0, duration: 0.8, ease: "power2.out" }, "-=0.8");
      tl.to(".cv", {
        onComplete: function () {
          router.push("/Acceuil");
        },
      });
      this.stopAnimation();
    },
    init3D() {
      this.scene = new THREE.Scene();
      this.scene.fog = new THREE.FogExp2(0x000000, 0.0002);
      this.camera = new THREE.PerspectiveCamera(
        90,
        window.innerWidth / window.innerHeight,
        0.1,
        1000
      );
      this.camera.lookAt(0, 0, 0);
      this.renderer = new THREE.WebGLRenderer({ antialias: true });
      this.renderer.setSize(window.innerWidth, window.innerHeight);
      let ecran = document.getElementById("ecran");
      ecran.appendChild(this.renderer.domElement);

      this.windowHalfX = window.innerWidth / 2;
      this.windowHalfY = window.innerHeight / 2;

      this.camera.position.z = 400;
      this.camera.position.x = 0;
      this.camera.position.y = 0;

      this.lightP = new THREE.PointLight(this.color, 10, 600);
      this.lightP.position.set(0, 0, 200);
      this.scene.add(this.lightP);

      var geometry = new THREE.BoxGeometry(20, 20, 100, 2, 2, 2);

      const loader = new THREE.TextureLoader();
      const texture = loader.load("textures/metal1.jpg");
      var material = new THREE.MeshStandardMaterial({
        metalness: 0.9,
        roughnessMap: texture,
        roughness: 0.2,
      });

      for (let i = 0; i < 60; i++) {
        const x = 400 * Math.random() - 200;
        const y = 300 * Math.random() - 150;
        const z = -4000 * Math.random() + 300;

        this.cube = new THREE.Mesh(geometry, material);
        this.cube.position.x = x;
        this.cube.position.y = y;
        this.cube.position.z = z;

        this.scene.add(this.cube);

        //ajout des lumieres
        if (i % 2 == 0) {
          //console.log(z);
          const light = new THREE.PointLight(this.color, 4, 320);
          light.position.set(x - 20, y + 20, z + 20);
          this.scene.add(light);
        }
      }

      this.camera.lookAt(this.scene.position);
      this.renderer.render(this.scene, this.camera);
    },
    animate() {
      this.idAnim = requestAnimationFrame(this.animate);

      this.cube.rotation.y += 0.02;
      this.camera.position.z -= 0.2;
      this.lightP.position.z = this.camera.position.z - 200;

      this.renderer.render(this.scene, this.camera);
    },
    stopAnimation() {
      console.log("cassé");
      cancelAnimationFrame(this.idAnim);
    },
  },
};
</script>

<style scoped lang="scss">
body {
  background-color: black;
}

.home {
  background-color: black;
}

.ecran {
  width: 100%;
  overflow: hidden;
}
.container1 {
  position: absolute;
  top: 100;
  background-color: transparent;
  width: 100%;
  scale: 0.5;
  transition: 0.4s ease-in;
  text-shadow: black 0.1em 0.1em 0.2em;
  overflow: hidden;
}

.detail {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: flex-start;
  background-color: transparent;
  padding: 20px 0 30px 0;
  opacity: 0;
  background-color: transparent;

  .front .back .ops {
    flex-basis: 33%;
    display: flex;
    flex-direction: column;
    background-color: transparent;
    transition: all 1s;
  }
}

.divers {
  background-color: transparent;
  opacity: 0;
}

.cv {
  border-top: 1px lightgray solid;
  background-color: transparent;
  padding: 30px 0 10px 0;
  opacity: 0;
  font-size: 18px;
  transition: 0.5s ease-out;

  p {
    cursor: pointer;
  }
}

.detail-titre {
  font-size: 30px;
  color: white;
  padding: 10px 10px 25px 10px;
  text-align: center;
  font-weight: bold;
}
.detail-txt {
  font-size: 15px;
  color: white;
  padding: 0 10px 15px 10px;
  text-align: center;
  background-color: transparent;
  box-sizing: content-box;
}

@media screen and (max-width: 750px) {
  .detail {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .entete {
    .titre {
      font-size: 0.8em;
    }
    .retour {
      font-size: 0.8em;
    }
  }
}
</style>
